import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Button from "../../../../components/button";
import Card from "../../../../components/card";
import Flex from "../../../../components/flex";
import useLearnByTopics from "../../../../components/learnByTopics/hooks/learnByTopics.hook";
import Section from "../../../../components/section";
import Text from "../../../../components/text";
import LearnByTopics from "../../../../components/learnByTopics";
import Breadcrumbs from "../../../../components/breadcrumbs";
import TotalTopicSearch from "../../../../components/total_topic_search";
import TopicsList from "../../../../components/topicsList";
import listImg from "../../../../assets/img/listImage.png";
import coloumImg from "../../../../assets/img/columnImage.png";
import Modal from "antd/es/modal/Modal";
import VideoPlayer from "../../../../components/videoplayer";
import CourseSearch from "../../../../components/courseSearch";
import PlayVideoCompnent from "../../../../components/playVideoCompnent";
import useVideosCommonHooks from "../../../instructor/createCourse/videosCommonHook";
import { DEFAULT_FILTERS } from "../../../../constants/app.constants";
import CategorySubcatModal from "../../../../components/courseSearch/categorySubcatModal";

const CoursePageView = (props) => {
  const { text } = props;
  const location = useLocation();
  const breadcrumbList = [
    {
      text: "Dashboard",
      link: "/trainee/dashboard",
    },
    {
      text: "Courses",
      link: "/trainee/courses",
    },
  ];

  const [isVideoModal, setIsVideoModal] = useState(false);
  const [vidoePlayUrl, setVidoePlayUrl] = useState("");

  const [getCategoryId,setCategoryId] = useState(0);
  const [getSubCategoryId,setSubCategoryId] = useState(0);
  const [getSearchName,setSearchName] = useState('');

  useEffect(() => {
    if(getCategoryId !=0  ){
    //  props?.commonHook.setkeys();
    DEFAULT_FILTERS.uri = 'fetchInstructorCourseList';
    if(location.pathname === "/trainee/courses/free-courses"){
      DEFAULT_FILTERS.coursetype = 0;
    }

    if(location.pathname === "/trainee/courses/subscription-courses"){
      DEFAULT_FILTERS.coursetype = 9;
    }

    if(location.pathname === "/trainee/favourite/favourite-course"){
      DEFAULT_FILTERS.type = "favourite";
    }
    DEFAULT_FILTERS.userid = "l";
    
    DEFAULT_FILTERS.category_id = getCategoryId;
    DEFAULT_FILTERS.subcategory_id = getSubCategoryId;
    DEFAULT_FILTERS.sortkey = "asc";
    DEFAULT_FILTERS.fromdate = "";
    DEFAULT_FILTERS.todate = "";
     props?.commonHook.InnergetList(DEFAULT_FILTERS)
    }
},[getCategoryId,getSubCategoryId,getSearchName])


  const playVideo = (val) => {
    props.commonHook.promiseServiceNonLoading({"uri":"getImagePath",'imagePath':val}).then((data) => {
      console.log("getImagePath--->",data.data)
      setVidoePlayUrl(data.data);
      setIsVideoModal(true);
    });
  };
  return (
    <div>
      {/* <Breadcrumbs
        breadcrumbList={breadcrumbList}
      /> */}
      <Section className="pt-3 lbt-section rml-24 overflow-outer">
        <div className="section-head mt-lg-2 mt-5">
          <Text
            type="H3"
            text="Courses"
            className="section-title text-uppercase fs-4"
          />
        </div>



        <CategorySubcatModal
                            commonHook={props.commonHook}
                            getCategoryId={getCategoryId}
                            getSubCategoryId={getSubCategoryId}
                            setCategoryId={setCategoryId}
                            setSubCategoryId={setSubCategoryId}
                            getSearchName={getSearchName}
                            setSearchName={setSearchName}
                    />



        <div className="row shadow bg-body rounded  p-2 text-right mb-4">
          <div className="col-md-12">
            <Button
              link
              text={
                <img src={listImg} alt="copy" className="icon-25 bg-none" />
              }
              style={{ margin: "0px", padding: "1px" }}
              onClick={() => {
                props.setDisplayType("list");
              }}
              type="button"
            />

            <Button
              link
              text={
                <img src={coloumImg} alt="copy" className="icon-25 bg-none" />
              }
              style={{ margin: "0px", padding: "1px" }}
              onClick={() => {
                props.setDisplayType("coloum");
              }}
              type="button"
            />
          </div>
        </div>

        
        <div className="row ">
            


            
          <TopicsList {...props} {...props.commonHook} playVideo={playVideo} displayType={props.displayType} />
        
      
        </div>

          
      </Section>

      {isVideoModal && (
        <Modal
          title=""
          centered
          open={isVideoModal}
          onOk={() => setIsVideoModal(false)}
          onCancel={() => setIsVideoModal(false)}
          zIndex={10000}
          width={window.innerWidth - window.innerWidth / 5}
          cancelButtonProps={{ style: { display: "none" } }}
          okButtonProps={{ style: { display: "none" } }}
        >
          <VideoPlayer videourl={vidoePlayUrl} width="100%" height="460" />
        </Modal>
      )}
    </div>
  );
};

export default CoursePageView;
