import React, {useState} from "react";
import CalendarIcon from "../svg/calendarIcon";
import ViewEyeIcon from "../svg/ViewEyeIcon";
import ClockIcon from '../svg/clockIcon';
import Text from "../text";
import './videoPlayersDetails.scss';
import Moment from 'moment';
import Button from '../button';
import Icon from '../icon';
import LikesIcon from "../svg/likesIcon";
import Avatar from '../avatar';
import avatarPic from '../../assets/images/avatar_pic2.jpg';
import StarIcon from '../svg/starIcon';
import useHelper from "../../hooks/helpers.hook";
import { useMediaQuery } from "react-responsive";
import { GURU } from "../../constants/app.constants";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import publishIcon from '../../assets/img/publish.png'
import draftIcon from '../../assets/img/draft.png'
import {toast} from "react-toastify";
import useVideosCommonHooks from "../../pages/instructor/createCourse/videosCommonHook";

const CourseDetailsPage = props => {
    const isMobile = useMediaQuery({query: '(max-width: 767px)'});
    const {formatDateDDMMMMYYYY, formatDateDDMMYY2, formatDateHms} = useHelper()
    const [showFullDescription, setShowFullDescription] = useState(false)
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState(null);

    const commonHook = useVideosCommonHooks();
    const handleChange = e => {
        setSelectedOption(e);

        var paramaters = {};
        paramaters.uri = "/instrutorCourseStatusChange";
        paramaters.courseId = props.ids[0];
        if(e.value == 1){
          paramaters.statusbit = 1;
        }else{
          paramaters.statusbit = 0;
        }
        commonHook.promiseService(paramaters).then((data) => {
         console.log("data",data.data.status.result);
         if(data.data.status.result === "200"){
          toast.success("Sucessfully Updated")
         }else{
          toast.error("something went wrong please try again")
         }
        });
        

      }
     

    const customStyles = {
        control: (base, state) => ({
          ...base,
          background: "#8081ff",
         
        }),
      
      };


    const publishData = [
        {
          value: 1,
          text: 'Publish Course',
          icon: <img src={publishIcon} alt="Publish Course" className='icon-25' />      
        },
        {
          value: 0,
          text: 'Draft Course',
          icon:  <img src={draftIcon} alt="Draft Course" className='icon-25' />      
        },
       
      ];

    return (
        <div className="video-players-details">
            <div className="vpd-upload-info">
                <div className="vpd-upload-info-left">
                    
                    <Text
                        type="H2"
                        className="title"
                        text={props?.title}
                    />
                  
                </div>
             
            </div>
          
            <div className="vpd-upload-info-desc">
                <Text type="H4" text="Description" className="text-black-half mr-0 font-weight-400"/>
                {props?.desc}
                   
            </div>
        
           
                    <div className="vpd-upload-info-desc mt-10">
                       
                            <div className="row">
                            {props.quizCount > 0 &&(
                              <>
                               <Text type="H4" text={"Total Quiz Questions added in this Course : " + props.quizCount } className="text-black-half mr-0 font-weight-400"/>
 <div className="col-md-3">
 <Button
 type="primary"

 text="PREVIEW Quiz"
 onClick={() => {
   props.setIsPopType("viewQuiz")
   props.setisShowPopUP(true);
 }}
 />
 
</div>
</>
                         )}
                           

                            {/*
                            <div className="col-md-4">
                                <Select
                                placeholder={<div className="select-placeholder-text">Update Course</div>} 
                                value={selectedOption}
                                options={publishData}
                                styles={customStyles}
                                onChange={handleChange}
                                getOptionLabel={e => (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    {e.icon}
                                    <span style={{ marginLeft: 5 }}>{e.text}</span>
                                </div>
                                )}
                                />
                            </div>
                                */}
                            <div className="col-md-3 mt-10" >
                                    Course Status : {props.statusbit == 0 &&( "Draft")}  {props.statusbit == 1 &&( "Published ")}
                            </div>
                            </div>
                            
                         
                    </div>
                  




        </div>
    )
}

export default CourseDetailsPage;
