import React, {useEffect, useState} from "react";
import './sidePanelLists.scss';
import '../navVideoDetailsSidePanelLists/navVideoDetailsSidePanelLists.scss'
import RibbonLeftIcon from '../svg/ribbonLeftIcon';
import ChevronIcon from "../svg/chevronIcon";
import SideMenuIcon from "../svg/sideMenuIcon";
import Tooltip from "../tooltip";
import Text from "../text";
import NavVideoDetailsSidePanelLists from "../navVideoDetailsSidePanelLists";
import {toast} from "react-toastify";
import useHttpHandler from "../../hooks/httphandler.hook";
import Loader from "../loader";
import Button from "../button";
import { useNavigate } from "react-router-dom";

import Icon from "../icon";
import playIcon from '../../assets/img/playVideo.png'
import { GURU } from "../../constants/app.constants";

const SidePanelLists = props => {
    const {post} = useHttpHandler()
    const [loading, setLoading] = useState(true)
    const [navSidepanelListOption, setNavSidepanelListOption] = useState(null)

    const getNavList = () => {
        setLoading(true)
        console.log("props--> usertypeid",props);
        var callUrl = "get_video_viewing_list";
        console.log("get_video_viewing_list_non_user",window.location.href.indexOf("course-preview"), window.location.href.indexOf("course"))
        //if(window.location.href.indexOf("course-preview") > -1 || window.location.href.indexOf("course") > -1) // This doesn't work, any suggestions?
        if(window.location.href.indexOf("course-preview") > -1 || window.location.href.indexOf("course") > -1 ) // This doesn't work, any suggestions?
        {
            
           callUrl = "get_video_viewing_list_non_user";
        }
        
        post(callUrl, {courseid: props.videoDetails.courseid}).then((res) => {
            const status = res.data.status
            const data = res.data.data
            if (status.result == '200') {
                props?.setCourseData(data);
                setNavSidepanelListOption(data)
                if (data[3].need_to_play[0].videoid) {
                    sessionStorage.setItem('nextVideoId',data[3].need_to_play[0].videoid)
                }
            } else {
                toast.error(status.msg)
            }
            setLoading(false)
        }).catch(err => {
            setLoading(false);
        })
    }


    useEffect(() => {
        getNavList()
    }, [])

    if (loading) {
        return <Loader/>
    }

    return (
        <div className="side-panel-lists-wrapper">
            <div
                className={`side-panel-lists-heading`}
            >
                <div
                    className="side-panel-lists-toggle"
                    onClick={props.onOpenIsSide}
                >
                    <div className="side-panel-icon">
                      {/* <RibbonLeftIcon width="80" height="55" bgcolor="#8081ff"/>
                        <span className={`side-panel-chevron-icon ${props.isSideOpen ? 'active' : ''}`}>
                            {props.isSideOpen ?
                                <ChevronIcon width="11" height="23" color="#fff"/>
                                :
                                <SideMenuIcon/>
                            }
                        </span> */}
                    </div>
                    <Tooltip className="white-space-nowrap" text={props.isSideOpen ? 'Minimize Panel' : 'Open Lesson Panel'}/>
                </div>
            </div>
            {props.isSideOpen && (
                <div className={`side-panel-lists-content`}>

                    
                    {navSidepanelListOption && navSidepanelListOption?.map(function (d, idx) {
                        if (d.totalvideos > 0) {
                            return <CatList item={d} isOpen={idx == 0} {...props}  usertypeid = {props?.usertypeid} />

                        }
                    })}
                </div>
            )}
        </div>
    )
}

export default SidePanelLists;

const CatList = (props) => {
    
    const {item, isOpen} = props
    const [isDropdown, setIsDropdown] = useState(true);

    
    return (
        <>
            <div className="spls-nav-card">
                <div
                    className={`spls-title pointer ${isDropdown ? 'active' : 'inactive'}`}
                    onClick={() => setIsDropdown(isDropdown => !isDropdown)}
                >
                    <Text type="SPAN" className="mr-2 " text={item.level}/>
                    <ChevronIcon
                        down={isDropdown ? false : true}
                        up={isDropdown ? true : false}
                        width="6"
                        height="13"
                    />
                </div>

                {isDropdown && (
                    <SubLists
                        getAllVideosWatched={props.getAllVideosWatched}
                        categories={item.data}
                        usertypeid = {props?.usertypeid}
                        setIsVideoModal = {props?.setIsVideoModal}
                        setVidoePlayUrl = {props?.setVidoePlayUrl}
                        props={props}
                    />
                )}
            </div>
        </>
    )
}

const SubLists = ({getAllVideosWatched, categories,usertypeid,setIsVideoModal,setVidoePlayUrl,props}) => {
    
    const navigate = useNavigate();

    const onClickNavItem = (video)=>{
        //getAllVideosWatched()
    
        if(usertypeid === GURU){
            navigate('/instructor/course-module-video/' + video.srno)    
        }else{
            navigate('/trainee/my-learning/video-details/' + +video.videoid)
        }

        
    }

    return (
        <>
            {categories?.map((cat, idx) => {

                const hasCategoryVideos = cat?.videos?.length > 0
                return (
                    <>
                        {cat?.totalvideos > 0 && (
                            <>

<ul className="nav-lesson " >
           
                        <>
                           <li className="lesson-item" onClick={()=>{onClickNavItem(cat)}}>
                                <div
                                    key={idx}
                                    className="lesson-item-block lesson-watched-half-way"
                                >
                                    <div className="lesson-item-left">
                                    <img src={playIcon} class="icon-32" alt="" />
                                    </div>
                                    <div className="lesson-item-right" style={{marginLeft:'10px'}}>
                                        <Text type="SPAN" text={`Module ${idx + 1}`}/>
                                        <Text type="H5" text={cat.category_name} className=" module-text"/>
                                    </div>
                                </div>
                            </li>
                        </>
                   
              
            </ul>                                
                            
                            </>
                        )}
                        {hasCategoryVideos && (
                            <>
                                <NavVideoDetailsSidePanelLists
                                    options={cat?.videos}
                                    getAllVideosWatched={getAllVideosWatched}
                                    usertypeid = {usertypeid}

                                />
                            </>
                        )}
                        {cat?.sub_category?.length > 0 && (
                            <>
                                {cat?.sub_category?.map((d, idx) => {
                                    if (d.videos.length > 0) {
                                        return (
                                            <SubCategoryListView d={d} getAllVideosWatched={getAllVideosWatched} usertypeid={usertypeid}/>
                                        )
                                    }

                                })}
                            </>
                        )}

                    </>
                )
            })}
        </>
    )
}

const SubCategoryListView = ({d, getAllVideosWatched,usertypeid}) => {
    
    const [isSubDropdown, setIsSubDropdown] = useState(d.iswatching > 0);
    return (
        <div className="dropdown-sublists">
            <div
                className={`dropdown-sublists-title ${isSubDropdown ? 'active' : ''}`}
                onClick={() => {setIsSubDropdown(isSubDropdown => !isSubDropdown);
                   
                }}
            >
                <Text type="SPAN" text={d.sub_cat}/>
                <ChevronIcon
                    down={isSubDropdown ? false : true}
                    up={isSubDropdown ? true : false}
                    width="6"
                    height="13"
                />
            </div>
            {isSubDropdown && (
                <NavVideoDetailsSidePanelLists
                    options={d.videos}
                    getAllVideosWatched={getAllVideosWatched}
                    usertypeid = {usertypeid}

                />
            )}
        </div>

    )
}


SidePanelLists.defaultProps = {
    /*navSidepanelListOption: [
        {
            levelSubtitle: 'Basic Level',
            levelTitle: 'BASIC FINANCE',
            options: [
                {
                    title: 'Celik Kewangan',
                    subOption: [
                        {
                            lessonNos: "1",
                            text:"Something 1",
                        },
                        {
                            lessonNos: "2",
                            text:"Something 1",
                        },
                    ]
                },
                {
                    title: 'Personal Management',
                    subOption: [
                        {
                            lessonNos: "1",
                            text:"Konsep kewangan",
                            watchedvideo: '1',
                        },
                        {
                            lessonNos: "2",
                            text:"Kehendak & keperluan",
                            watchedvideo: '1',
                        },
                        {
                            lessonNos: "3",
                            text:"Wang, sumber pendapa...",
                            watchedvideo: '1',
                        },
                        {
                            lessonNos: "4",
                            text:"Active income , passive...",
                            watchedvideo: '2',
                        },
                        {
                            lessonNos: "5",
                            text:"Jenis pekerjaan",
                            watchedvideo: '3',
                        },
                        {
                            lessonNos: "6",
                            text:"Tanggungjawab terhadap...",
                        },
                        {
                            lessonNos: "7",
                            text:"Apakah instrument kewan...",
                        },
                        {
                            lessonNos: "8",
                            text:"Mengenal pasti mata wang ",
                        },
                        {
                            lessonNos: "9",
                            text:"Membandingkan nilai mat...",
                        },
                        {
                            lessonNos: "10",
                            text:"Kontrak bertulis vs kontra...",
                        },
                        {
                            lessonNos: "11",
                            text:"Ikatan kontrak",
                        },
                    ]
                },
            ],
        },
        {
            levelSubtitle: 'Intermediate Level',
            levelTitle: 'INTERMEDIATE FINANCE',
            options: [],
        },
    ],*/
}
