import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../../components/button";
import CourseCard from "../../../../components/courseCard";
import InstructorImage from "../../../../assets/img/logo.svg";
import CourseImg from "../../../../assets/img/logo.svg";
import Table from "../../../../components/table";
import Pagination from "../../../../components/pagination";
import NoVideoUploadedYet from "../../../../components/noVideoUploadedYet";
import Icon from "../../../../components/icon";
import EditIcon from "../../../../components/svg/editIcon";
import Image from "../../../../components/image";
import { useMediaQuery } from "react-responsive";
import useHelper from "../../../../hooks/helpers.hook";
import Status from "../../../../components/status";
import Text from "../../../../components/text";
import Breadcrumbs from "../../../../components/breadcrumbs";
/*import Select from '../../../../components/input/select';*/
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import FlowCard from "../../../../components/flowCard";
import CourseImage from "../../../../assets/img/logo.svg";
import { Popconfirm, Result, Tag } from "antd";
import StaticVideoPlayer from "../../../../components/videoPlayers/staticVideoPlayer";
import TextHtmlParser from "../../../../components/htmlparser";
import VideoCard from "../../../../components/videocard";
import ImageVideoPopup from "../../../../components/imageVideoPopup";
import CourseMainBar from "../../../../components/courseMainbar";
import VideoPlayer from "../../../../components/videoplayer";
import Bin from "../../../../assets/img/Bin.png";
import { DEFAULT_FILTERS, URL } from "../../../../constants/app.constants";
import listImg from "../../../../assets/img/listImage.png";
import coloumImg from "../../../../assets/img/columnImage.png";
import cash from "../../../../assets/img/cash.png";
import privateCourse from "../../../../assets/img/PirvateCourse.png";
import free_course from "../../../../assets/img/free-course.png";
import ShareVia from "../../../../components/shareVia";
import ForgotPassword from "../../../../components/forgotpassword";
import Modal from "../../../../components/modal";
import Flex from "../../../../components/flex";
import rightIcon from "../../../../assets/img/rightIcon.png";
import { useSelector } from "react-redux";
import InstructorCourseCard from "../../../../components/instructorCourseCard";
import Section from "../../../../components/section";
import PlayVideoCompnent from "../../../../components/playVideoCompnent";
import useVideosCommonHooks from "../../createCourse/videosCommonHook";
import NoData from "../../../../components/noData";

const CourseContentPageView = (props) => {
  const { formatDateDDMMYY, getShortDescription } = useHelper();
  const { text, goToLearnerSignup, goToGuruSignup } = props;
  const { formatDateMMMDDYYYY } = useHelper();
  const [yearSelected, setYearSelected] = useState("Year 2022");
  const [monthSelected, setMonthSelected] = useState("Select Month");
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const navigate = useNavigate();
  const tableData = props?.tableData?.listData;
  const customData = props?.tableData?.customdata;
  const { homeVideos } = props;
  const userInfo = useSelector((state) => state.app.userInfo);
  const toggleIsModal = (video) => {
    setVideoDetails(video);
    setIsModal((state) => !state);
  };

  const commonHook = useVideosCommonHooks();

  const [displayType, setDisplayType] = useState("list");
  const [isModal, setIsModal] = useState(false);
  const [isShareModal, setIsShareModal] = useState(false);
  const [showVideoEnded, setShowVideoEnded] = useState(false);
  const [videoDetails, setVideoDetails] = useState(null);

  const [isVideoModal, setIsVideoModal] = useState(false);
  const [vidoePlayUrl, setVidoePlayUrl] = useState("");

  const playVideo = (val) => {
    commonHook?.promiseServiceNonLoading({"uri":"getImagePath",'imagePath':val}).then((data) => {
      setVidoePlayUrl(data.data);
      setIsVideoModal(true);
    });
  };

  const closeVideo = () => {
    setIsVideoModal(false);
  };

  const closeShare = () => {
    setIsShareModal(false);
  };

  const breadcrumbList = [
    {
      text: "Dashboard",
      link: "/instructor/dashboard",
    },
    {
      text: "Course",
      link: "/instructor/course",
    },
  ];

  const {
    dropdownsData,
    no_of_courses,
    no_of_videos,
    setCheckCategory,
    setCategoryId,
    getCategoryId,
    getSubCategoryId,
    setSubCategoryId,
    subCategories,
    setSubCategories,
  } = props;

  const [selectedCourseId, setSelectedCourseId] = useState(0);
  const [isDelete, setIsDelete] = useState(false);
  const deleteCourse = (data) => {
    setSelectedCourseId(data.courseid);
    setIsDelete(true);
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#8081ff",
    }),
  };

  const courseData = [
    {
      value: 0,
      text: "Free Course",
      icon: <img src={free_course} alt="" className="icon-25" />,
    },
    {
      value: 1,
      text: "Paid Course",
      icon: <img src={cash} alt="" className="icon-25" />,
    },
    {
      value: 2,
      text: "Private Course",
      icon: <img src={privateCourse} alt="" className="icon-25" />,
    },
  ];

  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (e) => {
    setSelectedOption(e);
    if (e.value == 1) {
      navigate("/instructor/create-course");
    } else if (e.value == 2) {
      navigate("/instructor/private-course");
    } else {
      navigate("/instructor/free-create-course");
    }
  };

  return (
    <>
      <div className="p-2">
        <Breadcrumbs breadcrumbList={breadcrumbList} />
        <div className="row my-3">
          <div className="col-md-9">
            <Text
              type="H1"
              text="Courses"
              className="mt-0 main_heading_color mb-0"
            />
          </div>

          <div className="col-md-3">
            <div className="">
              <Select
                placeholder={
                  <div className="select-placeholder-text">Create Course</div>
                }
                value={selectedOption}
                options={courseData}
                styles={customStyles}
                onChange={handleChange}
                getOptionLabel={(e) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {e.icon}
                    <span style={{ marginLeft: 5 }}>{e.text}</span>
                  </div>
                )}
              />
            </div>
          </div>
        </div>

        <FlowCard
          course="Instructor"
          coursetitle="Antonio"
          module="No.of Courses"
          value={customData?.no_of_courses}
          availablevideos="Total Videos"
          valuevideo={customData?.no_of_videos}
          dropdownsData={props?.dropdownsData}
          _props={props}
        />

        <div className="row shadow bg-color-grey-scale-1 rounded  py-2 text-right my-4">
          <div className="col-md-12">
            <Button
              link
              toolTip="List View"
              text={
                <img src={listImg} alt="copy" className="icon-25 bg-none" />
              }
              style={{ margin: "0px", padding: "1px" }}
              onClick={() => {
                setDisplayType("list");
              }}
              type="button"
            />

            <Button
              link
               toolTip="Coloum View"
              text={
                <img src={coloumImg} alt="copy" className="icon-25 bg-none" />
              }
              style={{ margin: "0px", padding: "1px" }}
              onClick={() => {
                setDisplayType("coloum");
              }}
              type="button"
            />
          </div>
        </div>


        {props?.tableData?.listData?.length == 0 &&(
           <NoData/>
        )
          
        }

        {displayType === "list" &&
          props?.tableData?.listData?.map(function (item, index) {
            return (
              <div className="row shadow bg-color-grey-scale-1 rounded  py-2 text-right my-4">
              <CourseMainBar
                index={index}
                playVideo={playVideo}
                data={item}
                setSelectedCourseId={setSelectedCourseId}
                deleteCourse={deleteCourse}
                showButtons={true}
                setIsShareModal={setIsShareModal}
              />
              </div>
            );
          })}
        {displayType === "coloum" && (
          <>
            <div className="row gy-3  mt-2 shadow bg-color-grey-scale-1 rounded p-2 pb-4">
              {props?.tableData?.listData?.map(function (item, index) {
                return (
                  <>
                    <div className="col-12 col-md-4">
                      <InstructorCourseCard
                        props={props}
                        data={item}
                        index={index}
                        playVideo={props.playVideo}
                        onClick={() => {
                          navigate("/trainee/course-details/" + item.courseid);
                        }}
                      />
                    </div>
                  </>
                );
              })}
            </div>
          </>
        )}
      </div>

      {isModal && (
        <>
          <Modal
            className="modal-signup-right-area"
            onOuterClose={setIsShareModal(false)}
            modalContent={
              <>
                <div className="homevideomodal-wrapper">
                  <div className="homevideomodal-main">
                    <StaticVideoPlayer
                      videoDetails={videoDetails}
                      showVideoEnded={showVideoEnded}
                      goToLearnerSignup={goToLearnerSignup}
                      videoEnded={setShowVideoEnded}
                    />
                  </div>

                  <div className="homevideomodal-content-bottom">
                    <Text type="H2" className="" text={videoDetails?.title} />

                    <TextHtmlParser
                      className="mb-0 text-white-half font-weight-400"
                      text={videoDetails?.des}
                    />
                  </div>
                </div>
              </>
            }
          />
        </>
      )}

      {isShareModal && (
        <>
          <Modal
            className="modal-white"
            canClose={closeShare}
            onClose={closeShare}
            modalContent={
              <ShareVia
                {...props}
                affiliateLink={
                  URL +
                  "/course/" +
                  selectedCourseId +
                  "-0-" +
                  userInfo?.myrefcode
                }
              />
            }
          />
        </>
      )}

      {isVideoModal && (
        <>
          <Modal
            className=""
            canClose="onClose"
            onClose={closeVideo}
            onOuterClose={closeVideo}
            modalContent={
              <VideoPlayer videourl={vidoePlayUrl} width="100%" height="460" />
            }
          />
        </>
      )}

      {isDelete && (
        <>
          <Modal
            className="modal-30-per"
            modalContent={
              <>
                <div className="row pop-up-header ">
                  <div class="col-md-12 div-center">
                    <img src={rightIcon} class="icon-50" alt="" />
                  </div>
                </div>
                <div className="row popup-padding">
                  <Text
                    type="H5"
                    text="Are you sure want to delete this course"
                    className="mb-10 mt-10 text-center"
                  />

                  <Flex justifyContent="center" className="mb-48 mt-40">
                    <Button
                      type="primary"
                      key="console"
                      text="Yes"
                      onClick={() => {
                        props.guruDashboardHook.instrutorDeleteCourse({
                          uri: "instrutorDeleteCourse",
                          courseId: selectedCourseId,
                        });
                      }}
                    />
                    <Button
                      type="primary"
                      key="console"
                      text="No"
                      onClick={() => {
                        setIsDelete(false);
                      }}
                    />
                  </Flex>
                </div>
              </>
            }
          />

          {/*
            <Modal
                title=""
                centered
                open={isDelete}
                onOk={() => setIsDelete(false)}
                onCancel={() => setIsDelete(false)}
                cancelButtonProps={{ style: { display: 'none' } }}
                okButtonProps={{ style: { display: 'none' } }}
                zIndex={10000}
            >

                    <Result
                        status="error"
                        title="Are you sure want to delete this course"
                        subTitle=""
                        width={300}
                        extra={[
                        <Button type="primary" key="console" text="Yes"  onClick={()=>{
                            
                            props.guruDashboardHook.instrutorDeleteCourse({"uri":"instrutorDeleteCourse","courseId":selectedCourseId})
                            
                        }}/>,
                        <Button type="primary" key="console" text="No"  onClick={()=>{
                            setIsDelete(false);
                        
                        }}/>
                        ]}
                    />
            
            </Modal>
                    */}
        </>
      )}

      {props.guruDashboardHook.deletedCourse && (
        <>
          <Modal
            className="modal-30-per"
            onOuterClose={playVideo}
            modalContent={
              <>
                <div className="row pop-up-header ">
                  <div class="col-md-12 div-center">
                    <img src={rightIcon} class="icon-50" alt="" />
                  </div>
                </div>
                <div className="row popup-padding">
                  <Text
                    type="H5"
                    text="Sucessfully deleted course"
                    className="mb-10 mt-10 text-center"
                  />

                  <Flex justifyContent="center" className="mb-48 mt-40">
                    <Button
                      type="primary"
                      key="console"
                      text="Done"
                      onClick={() => {
                        props.guruDashboardHook.setDeletedCourse(false);
                        DEFAULT_FILTERS.uri = "fetchInstructorCourseList";
                        DEFAULT_FILTERS.userid = "g";
                        DEFAULT_FILTERS.category_id = props.getCategoryId;
                        DEFAULT_FILTERS.subcategory_id = props.getSubCategoryId;
                        DEFAULT_FILTERS.coursetype = 1;
                        props.getList(DEFAULT_FILTERS);
                        props.guruDashboardHook.setDeletedCourse(false);
                      }}
                    />
                  </Flex>
                </div>
              </>
            }
          />
        </>
      )}
    </>
  );
};

export default CourseContentPageView;

CourseCard.defaultProps = {
  videoListOption: [],
};
