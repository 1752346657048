import React, { useContext, useState } from "react";
import { DragAndDrop, Drag, Drop } from "./drag-and-drop";
import { reorder } from "./helper";
import "./Drag.scss";
import { Accordion, AccordionContext, Card, useAccordionButton } from "react-bootstrap";
import CourseMainBar from "../../../../../components/courseMainbar";
import SortBar from "../../../../../components/sortBar";
import AddModuleComponent from '../../../../../components/addTopicCompnent/addModuleComponent';
import AddTopicComponent from '../../../../../components/addTopicCompnent/addTopicCompnent';
import Button from '../../../../../components/button';
import {Modal, Popconfirm,Result,Tag } from 'antd';
import EditModuleComponent from "../../../../../components/addTopicCompnent/editModuleComponent";
import EditTopicCompnent from "../../../../../components/addTopicCompnent/editTopicCompnent";
import viewImg from './../../../../../assets/img/Eye.png'
import Flex from "../../../../../components/flex";
import rightIcon from './../../../../../assets/img/rightIcon.png'
import Text from "../../../../../components/text";
import ConfirmMesage from "../../../../../components/confirm_messages";
import ConfirmSucess from "../../../../../components/confirm_sucess";
import { useNavigate, useParams } from "react-router-dom";
import AddQuizView from "../../../addQuiz/views/addQuizView";
import useGetTableList from "../../../../../hooks/getTableListCommonHook";
import useVideosCommonHooks from "../../../createCourse/videosCommonHook";
import ViewQuizView from "../../../addQuiz/views/viewQuizView";
import RepliesQuizView from "../../../addQuiz/views/repliesQuizView";
import ParticipateQuiz from "../../../addQuiz/views/participateQuiz";
import SortBarLesson from "../../../../../components/sortBar/sortbarLesson";
import informationIcon from '../../../../../assets/img/information.png'
import MeetingView from "../../../../../components/createMeeting/createMeeting";
import CreateMeeting from "../../../../../components/createMeeting/createMeeting";
import MyMeetingSlots from "../../../../../components/myMeetingSlots/myMeetingSlots";
import NoData from "../../../../../components/noData";
export const NestedListComponent = (props) => {

  const navigate = useNavigate();

  const commonHook = useVideosCommonHooks();

  var data = props?.data;

  const {courseId} = useParams();
 // console.log("props.data",props?.dropdownsData.moduleLevel);

  data.map((item, index) => {
    item.id = item.srno + "";
    item.videofilename = item.introvideo;
    item.lessons.map((litem, lindex) => {
      litem.id = litem.srno + "";
    })
    item.items = item.lessons;
  })

  console.log("data",data);

  const [categories, setCategories] = useState(data);

  const getTableListHook = useGetTableList();
 

  

  const handleDragEnd = (result) => {
    const { type, source, destination } = result;
    if (!destination) return;

    const sourceCategoryId = source.droppableId;
    const destinationCategoryId = destination.droppableId;

    // Reordering items
    if (type === "droppable-item") {
      // If drag and dropping within the same category
      if (sourceCategoryId === destinationCategoryId) {
        const updatedOrder = reorder(
          categories.find((category) => category.id === sourceCategoryId).items,
          source.index,
          destination.index
        );
        const updatedCategories = categories.map((category) =>
          category.id !== sourceCategoryId
            ? category
            : { ...category, items: updatedOrder }
        );

        var Priority = [];
        {updatedOrder.map((item, index) => {
          Priority.push({"priority":index,"srno" : item.srno})
      })}
      console.log("updatedSubCategories",updatedOrder,Priority);

      var paramaters = {};
      paramaters.uri = "/updateLessonsPriority";
      paramaters.priority = Priority;
      console.log("values",paramaters)
      

      props.promiseService(paramaters).then((data) => {

      
          
        
        });

        setCategories(updatedCategories);
      } else {
        const sourceOrder = categories.find(
          (category) => category.id === sourceCategoryId
        ).items;
        const destinationOrder = categories.find(
          (category) => category.id === destinationCategoryId
        ).items;

        const [removed] = sourceOrder.splice(source.index, 1);
        destinationOrder.splice(destination.index, 0, removed);

        destinationOrder[removed] = sourceOrder[removed];
        delete sourceOrder[removed];

        const updatedCategories = categories.map((category) =>
          category.id === sourceCategoryId
            ? { ...category, items: sourceOrder }
            : category.id === destinationCategoryId
            ? { ...category, items: destinationOrder }
            : category
        );
 var Priority = [];
        {updatedCategories.map((item, index) => {
          Priority.push({"priority":index,"srno" : item.srno})
      })}

      console.log("updatedSubCategories",updatedCategories,Priority);
         var paramaters = {};
      paramaters.uri = "/updateLessonsPriority";
      paramaters.priority = Priority;
      console.log("values",paramaters)
      

      props.promiseService(paramaters).then((data) => {

      
          
        
        });
        setCategories(updatedCategories);
      }
    }

    // Reordering categories
    if (type === "droppable-category") {
      const updatedCategories = reorder(
        categories,
        source.index,
        destination.index
      );

    
      
      var Priority = [];

      {updatedCategories.map((item, index) => {
          Priority.push({"priority":index,"srno" : item.srno})
      })}
      console.log("updatedCategories",updatedCategories,Priority);

      props.setLoading(true);
      var paramaters = {};
      paramaters.uri = "/updateModulePriority";
      paramaters.priority = Priority;
      console.log("values",paramaters)
      

      props.promiseService(paramaters).then((data) => {

      
          
        
        });
      
      setCategories(updatedCategories);
    }

   
    
  };

  const handleAdd = () => {
    console.log("1231312")
    setIsPopType('addModule');
    setisShowPopUP(true);
  };

  const handleAddMeeting = () => {
    console.log("1231312")
    setIsPopType('addMeeting');
    setisShowPopUP(true);
  };

  const [isShowPopUP,setisShowPopUP] = useState(false);
  const [isPopType,setIsPopType] = useState("");
  const [isDeletePopUP,setisDeletePopUP] = useState(false);
  const [questionId,setQuestionid]  = useState(0);

  const [selectedData, setSelectedData] = useState({});
  
  const deleteConfirmed = (type) => {
    if(type == "deleteModule"){
      props.guruDashboardHook.instrutorDeleteCourse({"uri":'instrutorDeleteModule',"moduleId":selectedData.srno}) 
    }
    if(type =="deleteLesson"){
      props.guruDashboardHook.instrutorDeleteCourse({"uri":'instrutorDeleteLesson',"lessonId":selectedData.videoid})                  
    }
  }

  const deleteCancelled = (type) => {
    if(type == "deleteModule"){
      setisDeletePopUP(false)
    }
    if(type =="deleteLesson"){
      setisDeletePopUP(false);
    }
  }

const confirmedSucess = (type) => {

    if(type == "Module"){
      props.guruDashboardHook.setDeletedCourse(false);
      props?.setCustomData(null);
      props.setLevel(true);
    }

    if(type =="Lesson"){
      props.guruDashboardHook.setDeletedCourse(false);
      props?.setCustomData(null);
      props.setLevel(true);
    }  

}
  


function callTopic(record){
  setSelectedData(record);
}

const callViewReplies = () =>{
  setisShowPopUP(false);
  
  setIsPopType("viewQuizRelpies")
  setisShowPopUP(true);
  
}

const callViewQuiz = () =>{
  setisShowPopUP(false);
  setIsPopType("viewQuiz")
  setisShowPopUP(true);
}


const closePopUp = () => {
  console.log("close")
  setisShowPopUP(false);
};


const ContextAwareToggle = ({ children, eventKey, callback }) => {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => callback && callback(eventKey),
  );

  

  return (
    <>

    <Button
     className="module-button-title-txt"
      
      link
      text={ 
        <>
          <img src={viewImg}  alt="copy" className='icon-25' />

          <Text
          type="SPAN"
           className="module-button-title-txt"
           text="View Lessons"
      />
      </>
      }
      style={{margin:'0px',padding:'1px'}}
      onClick={decoratedOnClick}
      type="button"
      children={children}
    />

    </>

  );
}


  return (
  <>



<div className="row">
    <div className="col-6">
    <Button
              type="primary"
              style={{
                marginBottom: 16,
               
              }}
              text="PREVIEW COURSE"
              onClick={() => {
                navigate("/instructor/course-preview/" + courseId + "-0");
              }}
            />
                  <Button
                          onClick={handleAdd}
                          type="primary"
                          style={{
                            marginBottom: 16,
                          }}
                          text="Add Module"
                  />
                  <Button
                          onClick={handleAddMeeting}
                          type="primary"
                          style={{
                            marginBottom: 16,
                          }}
                          text="Add Meeting"
                  />

                  
    </div>

    
</div>
<div className="row bg-white py-3">
<CourseMainBar
          index={100}
          playVideo={props.playVideo}
          data={props.commonHook.serviceData.course_details}
          showButtons={false}
          setIsShareModal={props.setIsShareModal}
        />


<MyMeetingSlots courseId={props.courseId}/>
</div>

<div className='row d-none' style={{marginLeft:'20px'}}>
<div className='row module-list-titlebox'>
<div className='col-1 ' >
<Text
    type="SPAN"
    className="module-title-text"
    text="Sort"
/>
</div>
<div className='col-2 col-md-3 col-xs-12'>
<Text
    type="SPAN"
    className="module-title-text"
    text="Video"
/>
    
</div>
<div className='col-2 col-md-3 col-xs-12'>
<Text
    type="SPAN"
    className="module-title-text"
    text="Title"
/>
    
</div>
<div className='col-2 col-md-3 col-xs-12'>
<Text
    type="SPAN"
    className="module-title-text"
    text="Date"
/>
    
</div>

<div className='col-2 col-md-3 col-xs-12'> 
</div>
</div>
</div>


    <DragAndDrop onDragEnd={handleDragEnd}>
      <Drop id="droppable" type="droppable-category">
        {categories.map((category, categoryIndex) => {
            
            var aheaderclass = ""
            if(categoryIndex % 2==0){
              aheaderclass =  "accordion-even-color width_111 pt-3 shadow-lg  bg-body rounded";
            }
            else {
              aheaderclass =  "accordion-odd-color pt-3 width_111";
            }


          return (
            
            <Drag
              className="draggable-category pt-2"
              key={category.id}
              id={category.id}
              index={categoryIndex}
              type={"module"}
            >
              <Accordion defaultActiveKey="0" style={{width:'100%'}} >
              <Card>
              
              <div className="category-container" >
                
                <Card.Header className={aheaderclass}>
                <SortBar index={categoryIndex} playVideo={props.playVideo} data={category} callTopic={callTopic} isLesson={false} setIsPopType={setIsPopType} setisShowPopUP={setisShowPopUP} setSelectedData={setSelectedData} setisDeletePopUP={setisDeletePopUP} viewButton={<ContextAwareToggle eventKey={"" + category.id}></ContextAwareToggle>}/>     
                </Card.Header>

                      <Accordion.Collapse eventKey={"" + category.id} className="child"  >

                            <Card.Body className="" >

                            {category.items.length == 0 &&(
                              <NoData />
                            )}
                            {category.items.length > 0 &&(
                                      <Drop key={category.id} id={category.id} type="droppable-item">
                                        {category.items.map((item, index) => {
                                          return (
                                              <Drag
                                              className="draggable container mb-4"
                                              key={item.id}
                                              id={item.id}
                                              index={index}
                                              type={"lesson"}
                                              >
                                              <SortBarLesson index={index} playVideo={props.playVideo} data={item} isLesson={true} setIsPopType={setIsPopType} setisShowPopUP={setisShowPopUP} setSelectedData={setSelectedData} setisDeletePopUP={setisDeletePopUP} questionId={questionId} setQuestionid = {setQuestionid}/>     
                                            </Drag>
                                          );
                                        })}
                                      </Drop>)
                                    }

                            </Card.Body>
                      </Accordion.Collapse>

                    </div>
              
              </Card>
              </Accordion>
            </Drag>
          );
        })}
      </Drop>
    </DragAndDrop>



    {isShowPopUP &&(
             <>
                  
                    <Modal
                      closable={false}
                      title=""
                      centered
                      open={isShowPopUP}
                      onOk={() => setisShowPopUP(false)}
                      onCancel={() => setisShowPopUP(false)}
                      cancelButtonProps={{ style: { display: 'none' } }}
                      okButtonProps={{ style: { display: 'none' } }}
                      width={window.innerWidth - window.innerWidth/4}
                      zIndex={10000}
                    >

                   {isPopType === 'addQuiz' &&(
                    <>
                          <AddQuizView 
                          {...getTableListHook} 
                          promiseService = {commonHook.promiseService}  
                          setisShowPopUP={setisShowPopUP} 
                          selectedData = {selectedData}
                          />
                      </>

                   
                    )}

                    {isPopType === 'viewQuiz' &&(


                        <ViewQuizView
                        {...getTableListHook} 
                        promiseService = {commonHook.promiseService}  
                        setisShowPopUP={setisShowPopUP} 
                        selectedData = {selectedData}
                        setisDeletePopUP = {setisDeletePopUP}
                        setIsPopType = {setIsPopType}
                        setQuestionid = {setQuestionid}
                        callViewReplies = {callViewReplies}
                        />
                
                  
                    )}


                    {isPopType === 'viewQuizRelpies' &&(
                            <RepliesQuizView
                            {...getTableListHook} 
                            promiseService = {commonHook.promiseService}  
                            setisShowPopUP={setisShowPopUP} 
                            selectedData = {selectedData}
                            questionId = {questionId}
                            callViewQuiz={callViewQuiz}                 
                            />
                    )}

                    
                    {isPopType === 'addLesson' &&(

                        <AddTopicComponent  
                        selectedData = {selectedData} 
                        closePopUp = {closePopUp}  
                        setCustomData = {props.setCustomData} 
                        setLevel={props.setLevel} 
                        setisShowPopUP={setisShowPopUP}
                        setisDeletePopUP = {setisDeletePopUP}
                        setIsPopType = {setIsPopType}
                        />
                    )}

                    {isPopType === 'editLesson'  &&(
                        <EditTopicCompnent
                        selectedData = {selectedData} 
                        closePopUp = {closePopUp}  
                        setCustomData = {props.setCustomData} 
                        setLevel={props.setLevel} 
                        setisShowPopUP={setisShowPopUP}
                        data = {selectedData}
                        />

                    )}


                    {isPopType === 'editModule'  &&(

                          <EditModuleComponent
                          moduleOptions = {props?.dropdownsData.moduleLevel} 
                          courseId = {props?.courseId} 
                          setisShowPopUP={setisShowPopUP} 
                          setLevel = {props?.setLevel}  
                          setCustomData = {props?.setCustomData} 
                          data = {selectedData}
                          />
                    )}


                    {isPopType === 'addModule' &&(

                    <AddModuleComponent
                    moduleOptions = {props?.dropdownsData.moduleLevel} 
                    courseId = {props?.courseId} 
                    setisShowPopUP={setisShowPopUP} 
                    setLevel = {props?.setLevel}  
                    setCustomData = {props?.setCustomData} 
                    setisDeletePopUP = {setisDeletePopUP}
                    setIsPopType = {setIsPopType}
                    setRandomNum={props.setRandomNum}
                    />
                    )}


                    {isPopType === 'addMeeting' &&(
                    <>
                    Create Meeting
                    <CreateMeeting
                    courseId = {props?.courseId} 
                    pageType="course" 
                    
                    setisShowPopUP={setisShowPopUP} 
                    setLevel = {props?.setLevel}  
                    setCustomData = {props?.setCustomData} 
                    setisDeletePopUP = {setisDeletePopUP}
                    setIsPopType = {setIsPopType}
                    />

                    </>
                    )}



                    </Modal>
            </>)
            
      }



              {isDeletePopUP &&(
                          <>
                          
                                <Modal
                                  closable={false}
                                  title=""
                                  centered
                                  open={isDeletePopUP}
                                  onOk={() => setisDeletePopUP(false)}
                                  onCancel={() => setisDeletePopUP(false)}
                                  cancelButtonProps={{ style: { display: 'none' } }}
                                  okButtonProps={{ style: { display: 'none' } }}
                                  zIndex={10000}
                              
                                >

                            
                                {isPopType === 'SucessAddedLesson' &&(

                                    <>

                                    <div className="row pop-up-header">
                                                <div class="col-md-6 div-center">
                                                    <img src={informationIcon} class="icon-50" alt="" />
                                                </div>
                                            </div>
                                            <div className="row popup-padding mt-20">

                                            <Text
                                        type="H5"
                                        text="Successfully Added Lesson"
                                        className="mb-10 mt-10 text-center"
                                    />

                                                <Button type="primary" key="console" text="Done"  onClick={()=>{
                                                    console.log("close");
                                                    
                                                    //props?.setCustomData(null);
                                                    props?.setLevel(true);
                                                    window.location.reload();
                                                
                                                }}/>
                                   

                                    </div>                              
                                    
                                    </>

                                ) 
                                        
                                }

                                {isPopType === 'SucessAddedModule' &&(

                                  <>

                                    <div className="row pop-up-header">
                                                <div class="col-md-6 div-center">
                                                    <img src={informationIcon} class="icon-50" alt="" />
                                                </div>
                                            </div>
                                            <div className="row popup-padding mt-20">

                                            <Text
                                        type="H5"
                                        text="Successfully Added Module"
                                        className="mb-10 mt-10 text-center"
                                    />

<Button type="primary" key="console" text="Done"  onClick={()=>{
                                            console.log("close");
                                            //props?.setRandomNum(Math.random(98289977888));
                                          //  
                                            props?.setLevel(true);
                                            window.location.reload();
                                        }}/>       

                                    </div>

                                    </>)

                                  }

                                  {isPopType === 'deleteModule' &&(
                                        <>
                                          <ConfirmMesage event={deleteConfirmed}  close={deleteCancelled} text="Are you sure want to delete this course" type="deleteModule"/>
                                        </>                
                                  )}

                                  {isPopType === 'deleteLesson' &&(

                                        <ConfirmMesage event={deleteConfirmed}  close={deleteCancelled} text="Are you sure want to delete this Lesson" type="deleteLesson"/>

                                  )}

                              </Modal>
                          </>)
                    
              }


              {props.guruDashboardHook.deletedCourse &&
            
                  <Modal
                      title=""
                      centered
                      open={props.guruDashboardHook.deletedCourse}
                      onOk={() => props.guruDashboardHook.setDeletedCourse(false)}
                      onCancel={() => props.guruDashboardHook.setDeletedCourse(false)}
                      cancelButtonProps={{ style: { display: 'none' } }}
                      okButtonProps={{ style: { display: 'none' } }}
                      zIndex={10000}
                      width={window.innerWidth - window.innerWidth/1.5}
                      cancelButtonProps={{ style: { display: 'none' } }}
                      okButtonProps={{ style: { display: 'none' } }}
                  >

                  {isPopType === 'deleteModule' &&(
                        <ConfirmSucess event={confirmedSucess}   text="Sucessfully deleted Module" type="Module"/>
                  )}

                  {isPopType === 'deleteLesson' &&(
                  <ConfirmSucess event={confirmedSucess}   text="Sucessfully deleted Lesson" type="Lesson"/>   
                  )}
            
                </Modal>
            
            }

    </>   
  );

  
  
};
