export const translations = {
    SIGN_UP: 'Sign up',
    SIGN_UP_v2: 'Sign up',
    LOGIN: 'Login',
    LOGOUT: 'Logout',
    LOG_IN: 'Log In',
    LOG_OUT: 'LogOut',
    DASHBOARD: 'Dashboard',
    BE_A_GURU: 'Be A Guru',
    MENU: {
        MENU_HOME:'Home',
        MENU_ABOUT: 'About',
        MENU_BENEFITS: 'Benefits',
        MENU_PRICING:'Pricing',
        MENU_TOPICS: 'Topics',
        MENU_TESTIMONY: 'Testimony',
        MENU_FEATURED_GURUS: 'Featured Gurus',
        MENU_KOL: 'Kol',
        MENU_PLAN: 'Plan',
        MENU_CONTACT:'Contact',
        MENU_BE_A_GURU: 'Be a guru',
    },
    ABOUT_REZKILAH: 'ABOUT COURSETSUNAMI',
    FORGOT_PASSWORD: 'Forgot Password?',
    EMAIL_ADDRESS: 'Email Address',
    CHANGE_PASSWORD:'Change Password',
    PASSWORD: 'Password',
    CONFIRM_PASSWORD: 'Confirm Password',
    CONFIRM: 'Sahkan',
    CANCEL: 'Batal',
    OR_CONTINUE_WITH: 'Or Continue with',

    REGISTER_NOW: 'Register Now',
    WELCOME_TO_REZKILAH: 'Welcome to COURSETSUNAMI',
    WELCOME_BACK: 'WELCOME BACK',
    WELCOME_DESCRIPTION: 'COURSETSUNAMI is a learning platform specialize in financing',
    GURU_WELCOME_DESCRIPTION: 'COURSETSUNAMI is a learning platform specialize in financing',
    USERNAME: 'Username',
    OR_SIGN_UP_WITH: 'Or Sign up with',
    ALREADY_HAVE_ACCOUNT: 'Already have account?',
    PHONE_NUMBER: 'Phone Number',
    REGISTER_AS_GURU: 'Register as Guru',
    REGISTER_AS_LEARNER: 'Register as Learner',
    I_HAVE_READ_AND_AGREE_TO_THE: 'I have read and agree to the',
    TERMS_OF_USE: 'Terms of use.',
    CREATE_A_LEARNER_ACCOUNT: 'Create a Learner Account',
    AUTOFILLED: '(autofilled)',
    AUTOFILLED_DESC: 'The data’s are fetched and from your social media. Please fill in all the blank field and set password for COURSETSUNAMI',
    CREATE_A_GURU_ACCOUNT: 'Create a Guru Account',
    OTP_VERIFICATION: 'OTP verification',
    WE_WILL_SEND_YOU_ONE_TIME_CODE_VERIFICATION_TO: 'We will send you one time code verification to',
    RESEND_OTP_IN: 'Resend OTP in',
    Go_BACK: 'Go Back',
    SUBMIT: 'Submit',
    SUBSCRIBE_TO_OUR_MONTHLY_PACKAGE: 'Subscribe to our monthly package',
    REZKILAH_PREMIUM_ACCOUNT: 'COURSETSUNAMI Premium Account',
    MONTHLY_SUBSCRIPTION: 'Monthly Subscription',
    CHOOSE_OUR_MONTHLY_PACKAGE_TO_VIEW_EXCLUSIVE_REZKILAH_FINANCE_VIDEO_AND_EARN_AFFILIATE_INCOME: 'Choose our monthly package to view exclusive COURSETSUNAMI finance video and earn affiliate income',
    SUBSCRIBE_NOW: 'Subscribe Now',
    IF_YOU_WISH_TO_PAY_LATER_YOU_WILL_HAVE_LIMITED_ACCESS_TO_OUR_100000_VIDEOS_AND_CONTENTS_WISH_TO_PROCEED:'If you wish to pay later, you will have limited access to our 100,000+ videos and contents. Wish to proceed?',
    YES_PAY_LATER: 'Yes, pay later',
    THANK_YOU_FOR_THE_PAYMENT: 'Thank you for the payment!',
    PAYMENT_SUCCESSFUL: 'Payment Successful',
    CONTINUE: 'Continue',
    SYSTEM_FAILED_TO_MAKE_PAYMENT : 'System failed to make payment!',
    PAYMENT_FAILED_PLEASE_CHECK_YOUR_INTERNET_CONNECTION_OR_CONTACT_YOUR_BANK_IMMEDIATELY: 'Payment Failed. Please check your internet connnection. Or contact your bank immediately.',
    RETURN_TO_PAYMENT: 'return to payment',
    AFFILIATE_PROGRAM: 'Affiliate Program',
    INVITE_YOUR_FRIEND_TO_RECEIVE_AFFILIATE_INCOME: 'Invite your friend to receive affiliate income',
    COPY_LINK: 'Copy Link',
    COPIED_LINK: 'Copied!',
    SHARE_VIA: 'Share via',
    SHARE_LATER: 'Share Later',
    MORE: 'More',
    HELP_US_TO_ACCOMODATE_YOUR_FINANCIAL_LEARNING_BY_ANSWERING_3_QUESTIONS: 'Help us to accomodate your financial learning by answering 3 questions',
    STEP_1_OF_3: 'Step 1 of 3',
    STEP_2_OF_3: 'Step 2 of 3',
    STEP_3_OF_3: 'Step 3 of 3',
    WHAT_ARE_YOU_LOOKING_FORWARD_TO_LEARNING_AT_REZKILAH: 'What are you looking forward to learning at COURSETSUNAMI?',
    WHAT_KIND_OF_TOPIC_INTERESTS_YOU_THE_MOST: 'What kind of topic interests you the most?',
    ARE_YOU_INTERESTED_IN_EARNING_AFFILIATE_INCOME : 'Are you interested in earning affiliate income?',
    TO_LEARN_BASIC_FINANCIAL_KNOWLEDGE: 'To learn basic financial knowledge',
    TO_EXPLORE_MORE_ABOUT_FINANCE_AND_OTHER_RELATED_KNOWLEDGES: 'To explore more about finance and other related knowledges',
    I_HAVE_ADVANCED_THE_KNOWLEDGE_IN_MY_OWN_FIELD_AND_WISH_TO_ADVANCED_MY_SKILLS_IN_OTHER_FIELDS: 'I have advanced the knowledge in my own field and wish to advanced my skills in other fields',
    ALL_OF_THE_ABOVE: 'All of the above',
    PROCEED: 'Proceed',
    GOBACK: 'Go Back',
    COMPLETE: 'Complete',
    YES: 'Yes',
    NO: 'No',
    INFORMATION_MENU: {
        PERSONAL_PARTICULARS : 'Personal Particulars',
        CORRESPONDENCE_ADDRESS : 'Correspondence Address',
        BANK_DETAILS :'Bank Details',
        WORK_AND_EXPERIENCE: 'Work and Experience',
        UPLOAD_ATTACHMENT: 'Upload Attachment',
    },
    PERSONAL_PARTICULARS : 'Personal Particulars',
    CORRESPONDENCE_ADDRESS : 'Correspondence Address',
    BANK_DETAILS :'Bank Details',
    WORK_AND_EXPERIENCE: 'Work and Experience',
    UPLOAD_ATTACHMENT: 'Upload Attachment',
    FULL_NAME_AS_PER_IC: 'Full Name (as per IC)',
    IDENTIFICATION_NUMBER_PASSPORT: 'Identification Number/Passport',
    DATE_OF_BIRTH: 'Date of Birth',
    LEVEL_OF_EDUCATION: 'Level of Education',
    OCCUPATION: 'Occupation',
    MONTHLY_RANGE_OF_INCOME_RM: 'Monthly Range of Income (RM)',
    FILL_UP_LATER: 'Fill up Later',
    FULL_NAME: 'Full Name',
    ADDRESS: 'Address',
    ADDRESS_LINE_1: 'Address Line 1',
    ADDRESS_LINE_2: 'Address Line 2',
    CITY: 'City',
    POSTAL_ZIP: 'Postal/Zip',
    STATE: 'State',
    COUNTRY: 'Country',
    BANK_NAME : 'Bank Name',
    YOUR_BANK_NAME : 'Your Bank Name',
    MY_PROFILE:'My Profile',
    ACCOUNT_HOLDER_NAME : 'Account Holder Name',
    ACCOUNT_HOLDER : 'Account Holder',
    ACCOUNT_NUMBER : 'Account Number',
    PROFILE_PHOTO: 'Profile Photo',
    PREFERRED_NAME: 'Preferred Name',
    ABOUT_YOURSELF: 'About Yourself',
    THANK_YOU_FOR_COMPLETING_YOUR_PROFILE : 'Thank you for completing your profile',
    YOU_APPLICATION_IS_CURRENTLY_BEING_REVIEWED_BY_ADMIN_YOU_WILL_BE_CONTACTED_WITHIN_2_WEEKS: 'You application is currently being reviewed by admin, you will be contacted within 2 weeks',
    GO_TO_DASHBOARD: 'Go to Dashboard',
    FIELD_OF_EXPERTISE: 'Field of Expertise',
    YEARS_OF_EXPERIENCE: 'Years of Experience',
    SELECT_THE_TOPIC_OF_YOUR_INTEREST : 'Select the topic of your Interest',
    WHAT_REZKILAH_OFFERS_TO_YOU: 'WHAT COURSETSUNAMI OFFERS TO YOU',
    MONTH: 'Month',
    AFFORDABLE_AND_WORTHWHILE_MONTHLY_FEES: 'Affordable and worthwhile monthly fees.',
    ACCESS_TO_EXCLUSIVE_VIDEOS: 'Access to exclusive videos',
    ACCESS_TO_EXCLUSIVE_VIDEOS_DESCRIPTION: 'Access to financial tips & knowledge videos exclusive to registered members.',
    CONNECT_WITH_EXPERTS: 'Connect with experts',
    CONNECT_WITH_EXPERTS_DESCRIPTION: 'Registered members can choose and participate in more in-depth learning courses and one-to-one sessions with certified experts.',
    GET_REWARDED: 'Get rewarded',
    GET_REWARDED_DESCRIPTION: 'You will profit just by becoming a member! Want to know how?',
    CLICK_ON_THIS_NETWORK: 'Click on this network',
    E_MAGAZINE: 'E-Magazine',
    MAGAZINE: 'Magazine',
    E_MAGAZINE_DESCRIPTION: 'Want to get the latest info and master efficient financial tips? Join our subscription now!',
    FINANCIALTOPICS: 'FINANCIAL TOPICS',
    AFFILIATES_INCOME: 'Affiliates Income',
    AFFILIATES_INCOME_DESCRIPTION: 'In cursus nisl id tincidunt ultricies. Aenean scelerisque, ex at egestas pulvinar, augue turpis malesuada diam, quis commodo metus felis vitae sem. Phasellus venenatis massa vitae purus tincidunt',
    AFFILIATION_GURU: 'Affiliation Guru:',
    CREDIT_INCENTIVE_UP_AFFILIATE: 'Credit incentive up to xx% Affiliate',
    COMMISSION_UP_TO: 'Commission Up to xx% - xx%',
    SUBJECT_TO_THE_TERMS_AND_CONDITIONS: '(Subject to the terms and conditions)',
    WHAT_PEOPLE_SAY_ABOUT_REZKILAH: 'WHAT PEOPLE SAY ABOUT COURSETSUNAMI',
    WHAT_PEOPLE_SAY_ABOUT_US: 'WHAT PEOPLE SAY ABOUT US',
    OUR_EXCLUSIVE_GURUS: 'OUR EXCLUSIVE GURUS',
    FEATURED_VIDEOS_BY_OUR_GURUS: 'FEATURED VIDEOS BY OUR GURUS',
    KEY_OPINION_LEADER: 'KEY OPINION LEADERS (KOLs) @ COURSETSUNAMI,',
    KOL: 'KOL',
    REZKILAH: 'COURSETSUNAMI',
    ARE_YOU_READY_TO_LEARN_AND_EARN_WITH_REZKILAH: 'Are you Ready to learn and Earn with COURSETSUNAMI?',
    GET_STARTED_NOW: 'Get started NOW!',
    FREE: 'Free',
    FEATURES: 'Features',
    FREEMIUM_LIST_1: '100+ executive-level courses',
    FREEMIUM_LIST_2: 'Guide and coach participants',
    FREEMIUM_LIST_3: 'AI-powered analytics and insights',
    FREEMIUM_LIST_4: 'Multimodal learning',
    FREEMIUM_LIST_5: 'Live, synchronous events',
    FREEMIUM_LIST_6: 'Course assignment',
    FREEMIUM_LIST_7: '',
    EXCLUSIVE_FEATURES: 'Exclusive Features',
    PREMIUM_LIST_1: '100+ executive-level courses',
    PREMIUM_LIST_2: 'Facilitation to guide and coach participants',
    PREMIUM_LIST_3: 'AI-powered analytics and insights',
    PREMIUM_LIST_4: 'Multimodal learning',
    PREMIUM_LIST_5: 'Live, synchronous events',
    PREMIUM_LIST_6: 'Course assignment with custom messaging',
    PREMIUM_LIST_7: 'Mobile-enabled platform',
    GURU_LIST_1: '100+ executive-level courses',
    GURU_LIST_2: 'Guide and coach participants',
    GURU_LIST_3: 'AI-powered analytics and insights',
    GURU_LIST_4: 'Multimodal learning',
    GURU_LIST_5: 'Live, synchronous events',
    GURU_LIST_6: 'Course assignment',
    DISCLAIMER: 'Disclaimer: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam blandit quam id pulvinar venenatis. Maecenas blandit ligula sed efficitur efficitur. Cras et elit nunc. Donec consectetur id leo eu consequat. Curabitur at urna a est posuere fringilla ut ac libero. Vivamus dictum ipsum felis, ut eleifend sapien consequat ac. Vivamus fermentum diam in mi ultrices ultricies. Aenean eu lobortis massa. Suspendisse vel pellentesque erat.',
    SHARE_LEARN_PROFIT: 'SHARE | LEARN | PROFIT',
    LOGO_DESCRIPTION: 'The financial Education platform evolves to encourage Bumiputeras to learn, share and earn profits.',
    QUICK_LINKS: 'QUICK LINKS',
    GURU: 'Guru',
    LEARNER: 'Learner',
    LANDING_PAGE: 'Landing page',
    GURU_SIGN_UP: 'Guru sign up',
    MEMBER_SIGN_UP: 'Member sign up',
    CONTACT: 'CONTACT',
    FREQUENTLY_ASKED_QUESTIONS: 'Frequently asked question',
    CONTACT_US: 'Contact us',
    LEGAL: 'LEGAL',
    TERM_OF_USE: 'Term of Use',
    PRIVACY_POLICY: 'Privacy Policy',
    TERMS_CONDITION: 'Terms & Condition',
    BACK_TO_MAIN_PAGE: 'Back to Main page',
    FOR_ANY_INFORMATION_AND_QUESTIONS: 'For any information and questions, please contact us via',
    LOGIN_TO_YOUR_ACCOUNT: 'Login to your account',
    SHARE_THE_AFFILIATE_LINK_EARN_REWARDS: 'Share AFFILIATE LINK! & EARN REWARDS!',
    SHARE_THE_AFFILIATE_LINK:'Share the Affiliate Link',
    LET_YOUR_FRIENDS_AND_FAMILY_LEARN_FINANCIAL_TOPICS_TO_ENHANCE_THEIR_KNOWLEDGE_HELP_THEM_WHILE_LEARNING_EXITING_REWARDS:'Let your frends and family learn financial topics to enhance their knowledge, help them while earning exciting rewards.',
    REZKILAH_BY_THE_NUMBERS:'COURSETSUNAMI BY THE NUMBERS',
    TOTAL_MEMBERS_SUBSCRIBED_IN_REZKILAH:'Total Members Subscribed In COURSETSUNAMI',
    TOTAL_MEMBERS_ENROLLED_BY_YOU:'Total Members Enrolled By You',
    TOTAL_VIDEO_VIEWED_BY_YOU:'Total Video Views By You',
    TOTAL_VIDEO_VIEWED_BY_ALL_MEMBERS:'Total Video Views By All Members',
    TOTAL_ACCUMULATED_EARNING_BY_YOU:'Total Accumulated Earning By You (RM)',
    MY_LEARNING:'MY LEARNING ',
    TOP_10_VIDEOS: 'TOP 10 VIDEOS',
    TRENDING:'TRENDING',
    LIKED_VIDEOS:'LIKED VIDEOS',
    ALL_TOPICS:'ALL TOPICS',
    LOAD_MORE:'LOAD MORE',
    MEMBER_COMISSION:'Member Commission',
    MEMBER_NETWORK:'Member Network',
    REPORT:'Report',
    OVERVIEW:'Overview',
    GENERATE_REPORT:'Generate Report',
    DATE:'Date',
    REFERAL_NAME:'Referral name',
    TYPE_DETAIL:'Type/Detail',
    AMOUNT:'Amount',
    MEMBER_ID:'Member Id',

    HELP_RECEIVE_HIS_REZKILAH_AFFILIATE_INCOME:'Help receive his COURSETSUNAMI affiliate income',
    BY_REGISTERING_AT_REZKILAH:'by registering at COURSETSUNAMI',
    SIGN_UP_NOW:'SIGN UP NOW',
    YOU_HAVE_OPPURTUNITY_TO_EARN_AFFILIATE_INCOME_AND_OTHER_COMMISSION:'**You have oppurtunity to earn affiliate income and other commission',
    PLANNING_TO_SHARE_YOUR_KNOWLEDGE_JOIN_US_NOW_AND_BECOME_A_GURU: 'Planning to share your knowledge? Join us now and Become a Guru',
    TOPICS_YOUARE_INTERESTED_WITH:'Topics you’RE interested with',
    SEARCH_RESULT_FOR:'SEARCH RESULT FOR',
    SEARCH_NOT_FOUND:'SEARCH NOT FOUND',
    YOUR_SEARCH_DID_NOT_MATCH_ANY_RESULTS:'Your Search Did Not Match Any Results',
    TO_CONTINUE_WATCHING_SUBSCRIBE_TO_OUR_PREMIUM_SUBSCRIPTION:'TO CONTINUE WATCHING, SUBSCRIBE TO OUR PREMIUM SUBSCRIPTION',
    TO_CONTINUE_WATCHING_ALL_OUR_CONTENT_ON_REZKILAH_WE_ENCOURAGE_YOU_TO_SUBSCRIBE_TO_OUR_MONTHLY_SUBSCRIPTION_AND_ENJOY_ALL_OUR_SERVICES_AND_fEATURES:'To Continue Watching all our Content on COURSETSUNAMI, we encourage you to subscribe to our monthly subscription and enjoy all our Services and Features',
    EXECUTIVE_LEVEL_COURSES_FROM_TOP_UNIVERSITIES:'100+ executive-level courses from top universities',
    FACILITATION_TO_GUIDE_AND_COACH_PARTICIPANTS:'Facillitation to guide and coach paticipants',
    AI_POWERED_ANALYTICS_AND_INSIGHTS:'Ai powered analytics and insights',
    MULTIMODAL_LEARNING:'Multimodal learning',
    LIVE_SYNCHRONOUS_EVENTS:'Live,synchronus events',
    COURSE_ASSIGNMENT_WITH_CUSTOM_MESSAGING:'Course assignment with custom messaging',
    MOBILE_ENABLED_PLATFORM:'Mobile enabled platform',
    SETTINGS:'Settings',
    PERSONAL_INFO:'Personal Info',
    ACCOUNT_ACTIVE:'Status Aktif',
    GENERAL_INFORMATION:'General Information',
    ROLE_OF_USER:'Role of User',
    MEMBERID:'Member ID',
    NAME:'Name',
    NRIC:'MyKAD',
    MONTHLY_RANGE_OF_INCOME:'Monthly Income ',
    CONTACT_INFORMATION:'Contact Information ',
    MEMBER_SUBSCRIPTION:'Member subscription ',
    BENEFITS_INCLUDED:'Benefits included',
    FULL_ACCESS_FOR_FINANCE_VIDEO:'Full access for finance video',
    MONTHLY_REZKILAH_MAGAZINE:'Monthly COURSETSUNAMI Magazine',
    CONNECT_WITH_FINANCE_EXPERT:'Connect with Finance Expert',
    GET_EXCLUSIVE_PAID_COMMISION:'Get exclusive paid commission',
    RECIEVED_REWARD_POINTS:'Received reward points',
    DAYS_REMAINING:'Days remaining',
    TOTAL_AFFILIATE_INCOME:'Total Affiliate Income',
    TOTAL_REWARD_POINTS:'Total Reward Points',
    TOTAL_VIDEO_VIEWING_MINUTES:'Total video viewing minutes',
    TOTAL_VIDEO_VIEWING_MINUTES_DESC:'You need to meet the set number of video viewing minutes (120 minutes) to be eligible to withdraw your affiliate earnings. Viewing minutes will only be taken into account when you watch the learning videos provided by our teachers.',
    TARGET_COMPLETED:'Target Accomplished',
    Report_GENERATION:'Report Generation',
    PAUSE_OR_CANCEL_SUBSCRIPTION:'Pause or Cancel Subscription',
    PAY_LATER:'Pay Later',
    VIEW_REPORT:'View Report',
    VIEW_DETAILS:'View Details',
    UPLOAD_COPY_OF_IDENTIFICATION_CARD_FRONT_AND_BACK:'Upload Copy of Identification Card (Front & Back)',
    DRAG_AND_DROP_HERE:'Drag and Drop here',
    UPLOADED_FILES:'Uploaded Files',
    LEARNER_SIDEBAR_MENU: {
        MAIN_PAGE : 'Main Page',
        LIKED_VIDEO : 'Liked Video',
        FAVOURITE_GURU :'Favourite Guru',
        MY_REPORT: 'My Report',
        MY_SUBSCRIPTION: 'My Subscription',
        MY_NETWORK:'My Network',
        MY_COMMISSION:'My Commission',
        PROFILE:'Profile',
        BANK_DETAILS:'Bank Details',
        ACCOUNT:'Account',
    },
    PLAN_SUMMARY: 'PLAN SUMMARY',
    TOTAL : 'Total ',
    PER_MONTH: 'month',
    PREMIUM_SUBSCRIPTION:  'Premium Subscription',
    BILLING_ADDRESS : 'Billing Address',
    DISCLAIMER_PAYMENT : 'By clicking the button below, you agree that COURSETSUNAMI will automatically continue your membership and charge membership fee (currently RM 99/month) to your payment method',
    PAY: 'PAY',
    READ_MORE: 'Read More',
    SEARCH_GURU_NAME: "Guru's Name",
    SEARCH_VIDEO_TOPICS: "Video's Topics",
    TARGET_MINUTES_TO_WATCH_THIS_MONTH: 'Target Minutes to watch This Month',
    TOTAL_VIDEO_MINUTES_VIEWED_THIS_MONTH: 'Total Video Minutes viewed This Month',
    BY_CANCELLING:'By cancelling your account you will lose all your data and currently active subscriptions as well',
    TOTAL_AFFILIATE_INCOME_FOR_THIS_MONTH: 'Total Affiliate Income for this Month',
    THE_REFERRAL_COMMISSION: 'The referral commission is based on monthly basis and maximum up to 8 tiers only.The referral commission is based on monthly basis and maximum up to 8 tiers only.',
    TOTAL_INCOME_PAID: 'Total Income Paid',
    TOTAL_REFERRED_MEMBERS: 'Total Referred Members',
    CREDIT_REWARD_POINT:'Credit Reward Point',
    THE_TOTAL_CREDIT_REWARD_POINT:'The total credit reward point is generated based on the total minutes viewed. 1 minute video viewing equivalent to 1 point. You can watch video repeatedly and the minutes will be counted.',
    TOTAL_CREDIT_REWARD_POINTS: 'Total Credit Reward Points',
    REWARDS_POINTS_THIS_MONTH: 'Reward Points this month',
    REWARDS_POINTS_LAST_MONTH: 'Reward Points last month',
    CREDIT_REWARDS_POINTS_DESC: 'Credit reward points can be redeemed based on view points accumulated. 1 Minute of video viewing is equivalent to 1 view points.',
    VIDEO_VIEWING: 'Video viewing',
    EVERY_MONTH_THERE_IS: 'Every month there is a specific target video viewing minutes for you to achieve. It is mandatory for you to achieve target minutes viewing to enjoy affiliate income. Video viewing time accumulation only applicable for videos from Guru and not promotional videos from Rezkilah.',
    VIDEO_MINUTES_VIEWED_THIS_MONTH: 'Video Minutes viewed this month',
    WATCH_VIDEO: 'Watch Video',
    TOTAL_VIDEO_MINUTES_VIEWED: 'Total Video Minutes Viewed',
    TARGET_MINUTES_PER_MONTH:'Target Minutes Per Month',
    REQUIRED_TO_FULFIL_THE_TARGET:'You are required to fulfil the target minutes of the month to claim commission from the system. If you does not fulfill the requirement, then the commission collected would be forfeited.',
    TOTAL_DOWNLINE:'Total Downline',
    SEARCH: 'Search',
    MONTH_V2: 'Month',
    IN_ORDER_TO_RECEIVE_COMMISSION:'In order to receive commission you need to fill in extra detail.',
    FILL_IN_EXTRA_DETAIL:'Fill in extra detail',
    BECOME_SUBSCRIBER_MODAL_CONTENT:'You need to become subscriber to unlock the content',
        AGREE_UNSUBSCRIBE:'By unsubscribing, you are agreeing to our Terms of Conditions and all your information will be deleted to our system.',
    ARE_YOU_SURE_UNSUBSCRIBE:'Are you sure you want to unsubscribe Premium Account in COURSETSUNAMI?',

    NEXT_BILLING_DATE_ON: 'Next billing date on',
    PLEASE_ENTER_A_ADDRESS:'Please Enter a address',
}
