import React, {useEffect} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import Text from '../../../../../components/text';
import Button from '../../../../../components/button';
import Flex from '../../../../../components/flex';
import Avatar from '../../../../../components/avatar';
import DP from '../../../../../assets/images/dp-100.png';
import Icon from '../../../../../components/icon';
import TickIcon from '../../../../../components/svg/tickIcon';
import Input from '../../../../../components/input';
import Select from '../../../../../components/input/select';
import useHttpHandler from "../../../../../hooks/httphandler.hook";
import {toast} from "react-toastify";
import useHelper from "../../../../../hooks/helpers.hook";
import InputFile from "../../../../../components/input/inputFile";
import axios from "axios";
import useUploadPhoto from "../../../../../hooks/upload.hook";


const EditPremiumProfileView = (props) => {
    const {text, dropdownsData, profileDetails, goToProfile} = props
    const {countries, states, cities, getCitiesByState} = props.addressInformationHook

    const {post} = useHttpHandler()
    const {formatDateYYYYMMDD} = useHelper()
    const profileImage = profileDetails?.pic ? process.env.REACT_APP_IMG_URL + profileDetails?.pic : DP
    const {uploadPhoto} = useUploadPhoto()

    const formik = useFormik({
        initialValues: {
            profilePhoto: '',
            file: '',
            username: profileDetails?.uname	 ? profileDetails?.uname	 : '',
            email	: profileDetails?.email	 ? profileDetails?.email	 : '',
            dateofBirth: profileDetails?.dob ? formatDateYYYYMMDD(profileDetails?.dob) : '',

            fullname: profileDetails?.fullname ? profileDetails?.fullname : '',
            identificationNumber: profileDetails?.ic_passportno ? profileDetails?.ic_passportno : '',
            phoneNumber: profileDetails?.mobile ? profileDetails?.mobile : '',
            addressLine1: profileDetails?.address1 ? profileDetails?.address1 : '',
            postalZipCode: profileDetails?.zipcode ? profileDetails?.zipcode : '',
            country: profileDetails?.countryid ? profileDetails?.countryid : '',

        },
        validationSchema: Yup.object({
           
            dateofBirth: Yup.string().required('Please enter a valid date of birth'),
            fullname: Yup.string().required('Please enter a valid full name'),
            identificationNumber: Yup.string().required('Please enter a valid identification number'),
            addressLine1: Yup.string().required('Please enter a valid address'),
            postalZipCode: Yup.string()
            .required('Please enter a valid postal zip code'),
            //.matches(/^[0-9]+$/, "Please enter a valid postal zip code")
           // .min(5, 'Please enter a valid postal zip code')
            //.max(5, 'Please enter a valid postal zip code'),


                country: Yup.string().required('Please enter a valid country'),
        }),
        onSubmit: (values, actions) => {
            console.log(values);
            actions.setSubmitting(true);
            let postData = {
                fullname: values.fullname,
                preferred_name: values.preferredName,
                ic_passportno: values.identificationNumber,
                address1: values.addressLine1,
                cityid: values.city,
                zipcode: values.postalZipCode,
                countryid: values.country,
                dob: values.dateofBirth,
            }

            uploadPhoto(values).then((responseData) => {
                let pic = profileDetails?.pic
                if (responseData?.data?.data) {
                    pic = responseData.data.data[0]
                }
                postData.pic = pic
                post('premium_profile_update', postData).then((res) => {
                    actions.setSubmitting(false);
                    const status = res.data.status
                    const data = res.data.data
                    if (status.result == '200') {
                        goToProfile();
                    } else {
                        toast.error(status.msg)
                    }
                }).catch(err => {actions.setSubmitting(false);})
            })

        },
    });

    const getCities = (e) => {
        formik.setFieldValue('state', e.target.value)
        getCitiesByState(e.target.value)
    }
    const UserStatusIcon = () => {
        if (profileDetails?.userstatus == 'Pending') {
            return <Icon small pending icon={<TickIcon width="12" height="8"/>}/>

        } else if (profileDetails?.userstatus == 'Rejected') {
            return <Icon small danger icon={<TickIcon width="12" height="8"/>}/>
        } else {
            return <Icon small success icon={<TickIcon width="12" height="8"/>}/>
        }

    }
    useEffect(() => {
        formik.setFieldValue('country', '1')
    }, [])

    return (
        <>
        <div className='change-password my-4 px-5 py-3 rounded-0'>
            <form onSubmit={formik.handleSubmit}>
               
                <Text
                    type="H3"
                    text="Edit Personal Info"
                    className="font-weight-500 my-3"
                />
                <div className="profile-dp mb-10">
                    {formik.values.file && (
                        <Flex className="upload-image-wrap">
                            <InputFile
                                label={text?.PROFILE_PHOTO}
                                type="file"
                                name="profilePhoto"
                                placeholder=""
                                thumb={formik.values.file}
                                value={formik.values.profilePhoto}
                                /*onChange={formik.handleChange}*/
                                onChange={(event) => {
                                    formik.setFieldValue("file", event.currentTarget.files[0]);
                                }}

                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.profilePhoto && formik.errors.profilePhoto ? (
                                    formik.errors.profilePhoto
                                ) : null}
                                title="Change Picture"
                                rounded
                                marginBottom='0'
                                className="profile-change-picture"
                            />
                        </Flex>
                    )}
                    {profileImage && !formik.values.file && (
                        <Avatar
                            w100
                            src={profileImage}
                            alt={profileDetails?.fullname}
                        />
                    )}

                    <div className="profile-dp-details">
                        <Text type="PARAGRAPH" text={profileDetails?.fullname} className="profile-dp-title"/>
                        <Text type="PARAGRAPH" text={profileDetails?.email} className="profile-dp-email"/>
                        <div className="account-status">
                            <UserStatusIcon/>
                            <Text type="PARAGRAPH" text={profileDetails?.userstatus}/>
                        </div>
                    </div>
                </div>
                <Flex justifyContent="start" className="mb-10">
                    <Input
                        tertiary
                        label="Change picture"
                        type="file"
                        name="profilePhoto"
                        placeholder=""
                        thumb={formik.values.file}
                        value={formik.values.profilePhoto}
                        /*onChange={formik.handleChange}*/
                        onChange={(event) => {
                            formik.setFieldValue("file", event.currentTarget.files[0]);
                        }}
                        onBlur={formik.handleBlur}
                        errormessage={formik.touched.profilePhoto && formik.errors.profilePhoto ? (
                            formik.errors.profilePhoto
                        ) : null}
                        className="profile-change-picture"
                    />
                </Flex>
                <div className="d-flex align-items-center mb-10">
                    <Text type="H4" text="General Information" className="mr-24 mb-0"/>
                </div>
                <div className="row">
                    <div className="col-12 col-md-4">
                        <div className="formBox">
                            <Input
                                tertiary
                                label="Username"
                                type="text"
                                name="username"
                                placeholder=""
                                readonly
                                value={formik.values.username}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.username && formik.errors.username ? (
                                    formik.errors.username
                                ) : null}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="formBox">
                            <Input
                                tertiary
                                label="Email Address"
                                type="text"
                                name="email"
                                placeholder=""
                                readonly
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.email && formik.errors.email ? (
                                    formik.errors.email
                                ) : null}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="formBox">
                            <Input
                                tertiary
                                label="Date of Birth"
                                type="date"
                                name="dateofBirth"
                                placeholder=""

                                value={formik.values.dateofBirth}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.dateofBirth && formik.errors.dateofBirth ? (
                                    formik.errors.dateofBirth
                                ) : null}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="formBox">
                            <Input
                                tertiary
                                label="Fullname (as per IC)"
                                type="text"
                                name="fullname"
                                placeholder=""

                                value={formik.values.fullname}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.fullname && formik.errors.fullname ? (
                                    formik.errors.fullname
                                ) : null}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="formBox">
                            <Input
                                tertiary
                                label="Identification Number"
                                type="text"
                                name="identificationNumber"
                                placeholder=""

                                value={formik.values.identificationNumber}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.identificationNumber && formik.errors.identificationNumber ? (
                                    formik.errors.identificationNumber
                                ) : null}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="formBox">
                            <Input
                                tertiary
                                label="Phone Number"
                                type="text"
                                name="phoneNumber"
                                placeholder=""
                                readonly
                                value={formik.values.phoneNumber}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                                    formik.errors.phoneNumber
                                ) : null}
                            />
                        </div>
                    </div>
                    {/*
                    <div className="col-12 col-md-4">
                        <div className="formBox">
                            <Select
                                tertiary
                                label="Level of Education"
                                name="levelofEducation"
                                value={formik.values.levelofEducation}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                options={dropdownsData?.educationOptions}
                                errormessage={formik.touched.levelofEducation && formik.errors.levelofEducation ? (
                                    formik.errors.levelofEducation
                                ) : null}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="formBox">
                            <Select
                                tertiary
                                label="Occupation"
                                name="occupation"
                                value={formik.values.occupation}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                options={dropdownsData?.occupationOptions}
                                errormessage={formik.touched.occupation && formik.errors.occupation ? (
                                    formik.errors.occupation
                                ) : null}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="formBox">
                            <Select
                                tertiary
                                label="Monthly Income"
                                name="monthlyIncome"
                                value={formik.values.monthlyIncome}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                options={dropdownsData?.salaryOptions}
                                errormessage={formik.touched.monthlyIncome && formik.errors.monthlyIncome ? (
                                    formik.errors.monthlyIncome
                                ) : null}
                            />
                        </div>
                    </div>*/}
                    <div className="col-12">
                        <Text type="H4" text="Correspondence Address" className="mb-24"/>
                    </div>
                    <div className="col-12 col-md-12">
                        <div className="formBox">
                            <Input
                                tertiary
                                label="Address"
                                type="text"
                                name="addressLine1"
                                placeholder=""

                                value={formik.values.addressLine1}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.addressLine1 && formik.errors.addressLine1 ? (
                                    formik.errors.addressLine1
                                ) : null}
                            />
                        </div>
                    </div>
                   
                    {/*
                     <div className="col-12 col-md-12">
                        <div className="formBox">
                            <Input
                                tertiary
                                type="text"
                                name="addressLine2"
                                placeholder=""

                                value={formik.values.addressLine2}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.addressLine2 && formik.errors.addressLine2 ? (
                                    formik.errors.addressLine2
                                ) : null}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="formBox">
                            <Select
                                tertiary
                                label="City"
                                name="city"
                                value={formik.values.city}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                options={cities}
                                errormessage={formik.touched.city && formik.errors.city ? (
                                    formik.errors.city
                                ) : null}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="formBox">
                            <Select
                                tertiary
                                label="State"
                                name="state"
                                value={formik.values.state}
                                onChange={getCities}
                                onBlur={formik.handleBlur}
                                mandatory
                                options={states}
                                errormessage={formik.touched.state && formik.errors.state ? (
                                    formik.errors.state
                                ) : null}
                            />
                        </div>
                    </div>*/}
                    <div className="col-12 col-md-4">
                        <div className="formBox">
                            <Input
                                tertiary
                                label="Postal/Zip Code"
                                type="text"
                                name="postalZipCode"
                                placeholder=""
                                maxlength="5"
                                value={formik.values.postalZipCode}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                errormessage={formik.touched.postalZipCode && formik.errors.postalZipCode ? (
                                    formik.errors.postalZipCode
                                ) : null}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        <div className="formBox">
                            <Select
                                tertiary
                                label="Country"
                                name="country"
                                value={formik.values.country}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                mandatory
                                options={countries}
                                errormessage={formik.touched.country && formik.errors.country ? (
                                    formik.errors.country
                                ) : null}
                            />
                        </div>
                    </div>
                </div>
                <div className="action-buttons-end pt-0 mt-0">
                    <Button
                        light
                        type="button"
                        text="Cancel"
                        onClick={goToProfile}
                        
                    />
                    <Button
                        type="submit"
                        text="Save"
                        className="btn-save mt-1"
                        isSubmitting={formik.isSubmitting}
                        disabled={formik.isSubmitting}
                    />
                </div>
            </form>
        </div>
        </>
    );
};

export default EditPremiumProfileView;

