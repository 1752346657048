import {BrowserRouter, Routes, Route, useNavigate,useHistory} from 'react-router-dom';
import HomeController from './pages/Home';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/grid.css';
import './assets/scss/color.scss';
import './assets/css/style.scss';
import './assets/css/generalSettings.css'
import {useEffect, useState} from "react";
import ProtectedRoute from "./components/protectedRoute";
import About from "./pages/about";

import {useDispatch, useSelector} from "react-redux";
import {ToastContainer,toast} from "react-toastify";
import useTranslations from "./hooks/translations.hook";
import {ENGLISH, MALAY} from "./languages/languages.constants";
import Login from './pages/authentication/login'
import ResetPassword from "./pages/authentication/resetPassword";
import Logout from "./pages/authentication/logout";

// Learner
import RegisterLearner from './pages/trainee/register';
import ReferenceLink from './pages/trainee/referenceLink';


// Guru
import RegisterGuru from './pages/instructor/register';

import ScrollToTop from "./components/scrolltotop";
import useHttpHandler from "./hooks/httphandler.hook";
import {setUserInfo} from "./store/reducers/app.reducer";
import useLogin from "./pages/authentication/login/hooks/login.hook";
import useHelper from "./hooks/helpers.hook";
import Loader from "./components/loader";
import {FREEMIUM, GURU, PREMIUM, PREMIUMPRO} from "./constants/app.constants";
import AboutUsPage from './pages/aboutUs';
import ContactUsPage from './pages/contactUs';
import PricingPage from './pages/pricing';
import ProductPage from './pages/newPages/productpage';
import CategeoryDetaisPage from './pages/newPages/instructorpages/categeoryDetails';
import UploadPage from './pages/newPages/instructorpages/uploadPage';
import InstructorDashboardpage from './pages/newPages/instructorpages/instructorDashboard';

import TableFormatPage from './pages/newPages/instructorpages/tableFormat';
import HomePage from './pages/Home';
import Register from './pages/authentication/register';
import WaitingListPage from './pages/waitingList';
import HomeCoursePreview from './pages/Home/homeCoursePreview';

import useChatHook from './pages/trainee/chat/hooks/chat.hook';
import song from "./assets/notification.wav";
import { getFirebaseToken, onForegroundMessage } from './config/firebase';
import NotificationCard from './components/NotificationCard';
import InstructorRoute from './instructorRoute';
import TrainerRoute from './trainerRoute';







function App() {
    
    const [loading, setLoading] = useState(true)
    const {post} = useHttpHandler()
    const {isGuruUser, isMember, hasToken, hasPermissions, updateUserMetaData} = useHelper()
    const dispatch = useDispatch()
    const userInfo = useSelector(state => state.app.userInfo)
    const {setDefaultLanguage} = useTranslations()
    const languageText = useSelector(state => state.app.languageText)
    const isMemberUser = isMember(FREEMIUM, PREMIUM,PREMIUMPRO)
    const isGuru = hasPermissions(GURU)
    const chatHook = useChatHook();
 
  //  

    
    const [showNotificationBanner, setShowNotificationBanner] = useState(Notification.permission === 'default');

    useEffect(() => {
      onForegroundMessage()
        .then((payload) => {
            console.log('Received foreground message: ',payload);
            let audio = new Audio(song);
            audio.play();
            toast(<ToastifyNotification title={payload.data.title} body={payload.data.data} />,{
            autoClose: 100000, // Set the duration to 3000 milliseconds (3 seconds)
          });
         
       //  
        })
        .catch(err => console.log('An error occured while retrieving foreground message. ', err));

       // handleGetFirebaseToken();
    }, []);
  
    const handleGetFirebaseToken = () => {
      getFirebaseToken()
        .then((firebaseToken) => {
          console.log('Firebase token: ', firebaseToken);
          if (firebaseToken) {
            setShowNotificationBanner(false);
          }
        })
        .catch((err) => console.error('An error occured while retrieving firebase token. ', err))
    }
  
    const ToastifyNotification = ({ title, body }) => (
        <>
        <NotificationCard title={title} body={body}/>

       
     
      </>
    );
  

   //const socket = 
   //const isMemberUser = hasPermissions(FREEMIUM, PREMIUM)
   //const isGuru = hasPermissions(GURU)

   
   

    useEffect(() => {
        //setDefaultLanguage(MALAY)
        setDefaultLanguage(ENGLISH)
        if (hasToken()) {
            if (!userInfo) {
                post('getProfile').then((res) => {
                    setLoading(false)
                    const status = res.data.status
                    const data = res.data.data
                    if (status.result == '200') {
                        
                        updateUserMetaData(data)
                    }
                }).catch(() => setLoading(false))
            }
        } else {
            setLoading(false)
        }

      
    }, [])
    if (loading) {
        return <Loader/>
    } else {
        return (
            <div className="App">
                <BrowserRouter>
                    <ToastContainer
                        autoClose={2000}
        />
                    <ScrollToTop/>
                    <Routes>
                        <Route path="/">
                            <Route index={true} element={<HomePage text={languageText}/>}/>
                            <Route path="referral/:referralCode" element={<ReferenceLink text={languageText}/>}/>
                            <Route path="/login" element={<Login text={languageText}/>}/>
                            <Route path="/register" element={<Register text={languageText}/>}/>
                            <Route path="/logout" element={<Logout text={languageText}/>}/>
                            <Route path="/resetPassword/:token" element={<ResetPassword text={languageText}/>}/>
                            <Route path="/setNewPassword" element={<Login text={languageText}/>}/>
                            <Route path="/forgotPassword" element={<Login text={languageText}/>}/>
                            <Route path="/ref-course/:id" element={<Login text={languageText}/>}/>
                          
                            <Route path="/course-preview/:courseId" element={<HomeCoursePreview text={languageText}/>}/>
                            <Route path="/course/:courseId" element={<HomeCoursePreview text={languageText}/>}/>
                           
                            <Route element={<ProtectedRoute isLoggedIn={()=>{hasToken()}}/>}>
                                <Route path="/about" element={<About />}/>
                                 <Route path="/test" element={<About/>}/>
                            </Route>
                            
                            <Route path="/about-us" element={<AboutUsPage text={languageText} />}/>
                            <Route path="/join-waiting-list" element={<WaitingListPage text={languageText} />}/>
                            <Route path="/pricing-page" element={<PricingPage text={languageText} />}/>
                            <Route path="/contact-us" element={<ContactUsPage text={languageText} />}/>
                           
                            <Route path='/product-page' element={<ProductPage text={languageText} />} />
                                <Route path='categeory-details' element={<CategeoryDetaisPage text={languageText} />} />
                                <Route path='upload-page' element={<UploadPage text={languageText} />} />
                                <Route path='instructor-dashboard-page' element={<InstructorDashboardpage text={languageText} />} />
                                <Route path='/table-page' element={<TableFormatPage text={languageText} />} />                               
                            </Route>
                            
                    </Routes>

                    <InstructorRoute isGuru={isGuru} languageText={languageText} />
                    <TrainerRoute languageText={languageText} isMemberUser={isMemberUser} />


                </BrowserRouter>
            </div>
        );
    }
}

export default App;
