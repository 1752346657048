
import {BrowserRouter, Routes, Route, useNavigate,useHistory} from 'react-router-dom';
import ProtectedRoute from "./components/protectedRoute";
import LearnerMain from "./pages/trainee/main";
// Guru
import RegisterGuru from './pages/instructor/register';
import OTPVerificationGuru from './pages/instructor/otpVerification';
import PersonalInformationGuru from './pages/instructor/personalInformation';
import AddressInformationGuru from './pages/instructor/addressInformation';
import WorkExperienceGuru from './pages/instructor/workExperience'
import BankDetailsGuru from './pages/instructor/bankDetails';
import UploadAttachmentsGuru from './pages/instructor/uploadAttachments'
import ThankYouGuru from './pages/instructor/thankYouGuru'
import GuruDashboard from "./pages/instructor/dashboard";
import GuruMyVideo from "./pages/instructor/myVideo";
import GuruUploadVideo from './pages/instructor/uploadVideo'
import GuruUploadVideoSuccess from './pages/instructor/uploadVideoSuccess'
import GuruVideoGuideline from './pages/instructor/videoGuideline'
import GuruProfile from './pages/instructor/settings/profile';
import GuruEditProfile from './pages/instructor/settings/profile/editProfile'
import GuruSettingsBankDetails from './pages/instructor/settings/bankDetails';
import GuruSettingsEditBankDetails from './pages/instructor/settings/bankDetails/editBankDetails';
import GuruSettingsAccount from './pages/instructor/settings/account';
import GuruMonetizationMyReport from './pages/instructor/monetization/myReport';
import GuruMonetizationMyCommission from './pages/instructor/monetization/myCommission';
import Modulespage from './pages/instructor/modulesContent';
import TopicPage from './pages/instructor/topicContent';
import TopicUploadpage from './pages/instructor/topicUpload';
import EditCoursePage from './pages/instructor/editCourse';
import ModuleUploadpage from './pages/instructor/moduleUpload';
import CourseContentPage from './pages/instructor/courseContent';
import CourseDetailsPage from './pages/trainee/courseDetails';
import ModuleCardEdit from './pages/instructor/moduleCardEdit';
import TopicCardEdit from './pages/instructor/topicCardEdit';
import CreateCourseStepBPage from './pages/instructor/createCourse/createeCourseStepb';
import FreeCourseContentPage from './pages/instructor/freeCourseContent';
import TotalLikes from './pages/instructor/totalLikes';
import TotalViews from './pages/instructor/totalViews';
import CoursePreview from './pages/trainee/coursePreview';
import AddQuizController from './pages/instructor/addQuiz';
import Chatboard from './pages/instructor/chat';
import InstructorChatHistory from './pages/instructor/chat/instructorChatHistory';
import MeetingPage from './pages/instructor/meetings';
import ChatDashboard from './pages/instructor/chat/chatDashboard';
import MeetingDashboard from './pages/instructor/meetings/meetingDashboard';
import ChatEndConversation from './pages/trainee/chat/chatEndConversation';
import TotalMinutes from './pages/instructor/chat/dashboard/totalMinutes';
import EarningThisMonth from './pages/instructor/chat/dashboard/earningThisMonth';
import TotalEarnings from './pages/instructor/meetings/meetingTotalEarnings';
import InstructorWallet from './pages/instructor/chat/dashboard/instructorWallet';
import MeetingTotalEarnings from './pages/instructor/meetings/meetingTotalEarnings';
import MeetingList from './pages/instructor/meetings/meetingList';
import VideoConference from './pages/instructor/meetings/videoConference';
import ConfirmedMeetingList from './pages/instructor/meetings/confirmedMeetingList';




function InstructorRoute({isGuru,languageText}) {
    
    
  
        return (

                      
                        
                        <Routes>
                           <Route path="/instructor">
                           <Route path="signup/" element={<RegisterGuru text={languageText}/>}/>
                           <Route path="signup/socialsignup" element={<RegisterGuru text={languageText}/>}/>
                                <Route element={<ProtectedRoute isLoggedIn={isGuru}/>}>
                                
                                <Route index={true} element={<RegisterGuru text={languageText}/>}/>
                                <Route path="otp-verification" element={<OTPVerificationGuru text={languageText}/>}/>
                                <Route path="course" element={<CourseContentPage text={languageText} />} />
                                <Route path="course-details/:courseId" element={<CourseDetailsPage text={languageText}/>}/>
                                <Route path="free-course" element={<FreeCourseContentPage text={languageText} />} />
                                <Route path="create-course" element={<CreateCourseStepBPage text={languageText} />}/>
                                <Route path="private-course" element={<CreateCourseStepBPage text={languageText} />}/>
                                <Route path="add-quiz/:courseId" element={<AddQuizController text={languageText} />}/>
                              
                                <Route path="free-create-course" element={<CreateCourseStepBPage text={languageText} />}/>
                                <Route path="create-course-step-2" element={<CreateCourseStepBPage text={languageText} />}/>
                                <Route path="edit-course/:courseId" element={<EditCoursePage text={languageText} />}/>
                                <Route path="modules/:courseId" element={<Modulespage text={languageText} />}/>
                                <Route path="create-module/:courseId" element={<ModuleUploadpage text={languageText} />}/>
                                <Route path="edit-module/:courseId" element={<ModuleCardEdit text={languageText} />}/>
                                <Route path="topics/:moduleId" element={<TopicPage text={languageText} />}/>
                                <Route path="create-topic/:moduleId" element={<TopicUploadpage text={languageText} />}/>
                                <Route path="edit-topic/:moduleId" element={<TopicCardEdit text={languageText} />}/>
                                <Route path="personal-information" element={<PersonalInformationGuru text={languageText}/>}/>
                                <Route path="address-information" element={<AddressInformationGuru text={languageText}/>}/>
                                <Route path="work-experience" element={<WorkExperienceGuru text={languageText}/>}/>
                                <Route path="bank-details" element={<BankDetailsGuru text={languageText}/>}/>
                                <Route path="upload-attachments" element={<UploadAttachmentsGuru text={languageText}/>}/>
                                <Route path="thankyou" element={<ThankYouGuru text={languageText}/>}/>
                            
                                <Route path="chat-board/:presentRoomName" element={<Chatboard text={languageText}/>}/>


                                <Route path="dashboard" element={<GuruDashboard text={languageText}/>}/>
                                <Route path="dashboard">
                                    <Route path="total-likes" element={<TotalLikes text={languageText}/>}/>
                                    <Route path="total-views" element={<TotalViews   text={languageText}/>}/>
                                    <Route path="total-commission" element={<GuruMonetizationMyCommission text={languageText}/>}/>
                                </Route>




                                <Route path="chat" element={<ChatDashboard text={languageText} />} />
                                <Route path="chat">
                                    <Route path="total-minutes" element={<TotalMinutes text={languageText}/>}/>
                                    <Route path="earning-month" element={<EarningThisMonth   text={languageText}/>}/>
                                    <Route path="total-earnings" element={<TotalEarnings text={languageText}/>}/>
                                    <Route path="instructor-wallet" element={<InstructorWallet text={languageText}/>}/>
                                    <Route path="chat-history" element={<InstructorChatHistory text={languageText}/>}/>
                                </Route>


                                <Route path="meeting" element={<MeetingDashboard text={languageText} />}/>
                                <Route path="meeting">
                                    <Route path="create-meeting" element={<MeetingPage text={languageText} />}/>
                                    <Route path="video-meeting" element={<VideoConference/>}/>
                                    <Route path="pending-meeting-list" element={<MeetingList text={languageText}/>}/>
                                    <Route path="confirmed-meeting-list" element={<ConfirmedMeetingList text={languageText}/>}/>
                                    <Route path="earning-month" element={<EarningThisMonth   text={languageText}/>}/>
                                    <Route path="total-earnings" element={<MeetingTotalEarnings text={languageText}/>}/>
                                    
                                </Route>

                             
                                <Route path="chat-conversation-end/:sessionId" element={<ChatEndConversation text={languageText} />} />
                                <Route path="my-video" element={<GuruMyVideo text={languageText}/>}/>
                                <Route path="upload-video" element={<GuruUploadVideo text={languageText}/>}/>
                                <Route path="video-details/:videoId" element={<GuruUploadVideoSuccess text={languageText}/>}/>
                                <Route path="course-module-video/:videoId" element={<LearnerMain text={languageText}/>}/>
                                <Route path="course-video-details/:videoId" element={<LearnerMain text={languageText}/>}/>
                                <Route path="course-preview/:courseId" element={<CoursePreview text={languageText}/>}/>
                                <Route path="video-guideline" element={<GuruVideoGuideline text={languageText}/>}/>

                                
                                <Route path="monetization">
                                    <Route path="my-report" element={<GuruMonetizationMyReport text={languageText}/>}/>
                                    <Route path="my-commission" element={<GuruMonetizationMyCommission text={languageText}/>}/>
                                </Route>


                               
                                <Route path="settings">
                                    <Route path="profile" element={<GuruProfile text={languageText}/>}/>
                                    <Route path="profile/edit-profile" element={<GuruEditProfile text={languageText}/>}/>
                                    <Route path="bank-details" element={<GuruSettingsBankDetails text={languageText}/>}/>
                                    <Route path="bank-details/edit-bank-details" element={<GuruSettingsEditBankDetails text={languageText}/>}/>
                                    <Route path="account" element={<GuruSettingsAccount text={languageText}/>}/>
                                </Route>
                                <Route path="*" element={<p>There's nothing here: 404!</p>}/>
                            </Route>

                            
                            </Route>
                            
                        </Routes>


       


        );
   
}

export default InstructorRoute;
