import React, {useEffect, useState} from "react";
import Text from "../text";
import './learnByTopics.scss';
import Flex from "../flex";
import Button from "../button";

import useHelper from "../../hooks/helpers.hook";
import Loader from "../loader";
import topicVideoPoster from "../../assets/images/topic-video-poster.jpg";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import TopicVideoCard from "../topicVideoCard";
import TopicFlowCard from "../topic_flow_Card";
import FlowCard from "../flowCard";
import LearnerCourseMainbar from "../learnerCourseMainbar";
import Modal from "../modal";
import ShareVia from "../shareVia";
import { useSelector } from "react-redux";
import { URL } from "../../constants/app.constants";





const   VideosList = (props) => {
    
    const navigate = useNavigate()
    const {getShortDescription_dot} = useHelper()
    const {videosListLoading, videosList} = props
    const {videoId} =useParams();
   
    
      

        if (props?.tableData.listData && props?.tableData.listData.length > 0) {

            if(props.displayType === 'list'){


                return (props?.tableData.listData?.map((item, index) => {
                    return(
                   <>
                   
                         <LearnerCourseMainbar props={props} data={item} index={index} playVideo={props.playVideo} setSelectedCourseId={props.setSelectedCourseId} setIsShareModal={props.setIsShareModal}/>     
                         </>)
                       
                }))
            }else{
                return(<>
                {/* <div className="row gy-4 mt-0 px-0 mx-n2">  */}
               
                
                {props?.tableData.listData?.map((item, index) => {
                    return(
                   <>
                         <div className="col-12 col-md-4 mb-4">
                            {/*
                        <TopicVideoCard
                        props={props}
                         data={item} 
                         index={index} 
                         playVideo={props.playVideo}
                        poster={item.thumbnail}
                        categeoryname="categeoryname"
                        courseId={item.courseid}
                        categeorytitle={item.catName}
                        subcategeory="subcategeory"
                        subcategeorytitle={item.subCatName}
                        title={getShortDescription_dot(item.title,45)}
                        desc={getShortDescription_dot(item.des,160)}
                        heart="25"
                        imgWidth="320"
                        imgHeight="233"
                        onClick={() => {
                            navigate('/trainee/course-details/' + item.courseid)
                        }}
                        duration={item.videoduration}
                        watchedupto={item?.watchedupto}
                        avatarSrc={topicVideoPoster}
                        />*/}

                                <TopicVideoCard
                                    props={props}
                                    data={item} 
                                    index={index} 
                                    playVideo={props.playVideo}
                                    setSelectedCourseId={props.setSelectedCourseId} 
                                    setIsShareModal={props.setIsShareModal}
                                
                                />         
        
                         </div>
                         </>)
                       
                })}
               
            {/* </div>  */}
            </>)
            }





            
                       
           
        }else{
        return <div className="col-12"><Text type="H3" className="text-center py-5" text="No Courses available"/></div>
        }
    


        /*  {props.displayType != 'list' &&(

                    <>
                    

                   <div className="col-12 col-md-4">
       
                   <TopicVideoCard
                   poster={item.thumbnail}
                   categeoryname="categeoryname"
                   courseId={item.courseid}
                   categeorytitle={item.catName}
                   subcategeory="subcategeory"
                   subcategeorytitle={item.subCatName}
                   title={item.title}
                   desc={item.des}
                   heart="25"
                   imgWidth="320"
                   imgHeight="233"
                   onClick={() => {
                       navigate('/trainee/course-details/' + item.courseid)
                   }}
                   duration={item.videoduration}
                   watchedupto={item?.watchedupto}
                   avatarSrc={topicVideoPoster}
                 />
               
           </div>
                    </>
               )}*/

}


const TopicsList = (props) => {
   
    console.log("props?.tableData",props?.tableData);
    
   
    if(props?.extraParams?.type == "get_today_added_course"){
     //console.log("date->",useHelper.formatDateYYYYMMDD(new Date()));
        //learnByTopicsHook.filterKeys.fromdate = props?.extraParams?.from_date
    }else{

    }
    
    
        const [active, setActive] = useState({catId: 0, catName: 'All topics'});
        const showLoadMore = props?.tableData.listData && Object.keys(props?.tableData.listData).length >= 8 && props?.tableData.totalRecords > Object.keys(props?.tableData.listData).length
       
        const userInfo = useSelector((state) => state.app.userInfo);


        const [isShareModal, setIsShareModal] = useState(false);
        const [showVideoEnded, setShowVideoEnded] = useState(false);
        const [selectedCourseId, setSelectedCourseId] = useState(0);
    
        const closeShare = () => {
            setIsShareModal(false);
          };
    
        


    return (
        <>
               

               {isShareModal && (
             <Modal
             className="modal-white"
             canClose={closeShare}
             onClose={closeShare}
             modalContent={
               <ShareVia
                 {...props}
                 affiliateLink={
                   URL +
                   "/course/" +
                   selectedCourseId +
                   "-0-" +
                   userInfo?.myrefcode
                 }
               />
             }
           />
          )}

          


                        <VideosList {...props} playVideo={props?.playVideo} setIsShareModal={setIsShareModal} setSelectedCourseId={setSelectedCourseId} />
                     
               

                {showLoadMore && (
                    <Flex justifyContent="center">
                        <Button type="button" outline large text="Load More" onClick={() => {
                            props.setFilters(active.catId, props.filterKeys.page + 1)
                        }}/>
                    </Flex>
                )}
            
        </>
    )
}

export default TopicsList;
