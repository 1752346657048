import React, {useState} from 'react';
import './switchButton.scss';
import Text from '../text';

const SwitchButton = props => {
    const [isToggled, setIsToggled] = useState(props?.status);
    const onToggle = () => {
        setIsToggled(!isToggled)
        props?.onChange()
        
        {props?.courseStatusChange(isToggled)}    
    };
    return (
        <label className="toggle-switch">
            <input type="checkbox" checked={isToggled} onChange={onToggle}/>
            <span className="switch">


                    {isToggled === 1 &&(
                        
                        <>
                            <Text type="H5" text="PUBLISHED" className="text-right switch-innertext p-r-40"/>
                           
                        </>

                    )

                    }


                    {isToggled === true &&(
                        
                        <>
                            <Text type="H5" text="PUBLISHED" className="text-right switch-innertext p-r-40"/>
                           
                        </>

                    )

                    }



                    {isToggled === 0  &&(
                        <>
                             <Text type="H5" text="Draft" className="text-left switch-innertext p-l-35" />
                           

                        </>

                    )
                }


                    {isToggled == false &&(
                        <>
                             <Text type="H5" text="Draft" className="text-left switch-innertext p-l-35" />
                           

                        </>

                    )

                    }


            </span>
        </label>
    );
};

export default SwitchButton;
