import React, {useEffect, useState} from 'react';
import Text from "../../../../components/text";
import Breadcrumbs from '../../../../components/breadcrumbs';
import VideoPlayers from '../../../../components/videoPlayers';
import VideoPlayersDetails from '../../../../components/videoPlayersDetails';
import SidePanelLists from '../../../../components/sidePanelLists';
import AvatarPic from "../../../../assets/images/avatar_pic.jpg";


import {useLocation, useNavigate, useParams} from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import { Navigation } from 'swiper';
import 'swiper/css/navigation';
import 'swiper/css';
import VideoPlayer from '../../../../components/videoplayer';

import { useSelector } from 'react-redux';
import { GURU } from '../../../../constants/app.constants';
import Modal from '../../../../components/modal';
import HeroSlider from '../../../../components/heroslider';
import CourseDetailsPage from '../../../../components/courseDetailsPage';
import LineModule from '../../../../components/lineModule';
import PlayVideoCompnent from '../../../../components/playVideoCompnent';
import useVideosCommonHooks from '../../../instructor/createCourse/videosCommonHook';


const HomeCourseMainView = (props) => {
    const {isSideOpen, onOpenIsSide, videoDetails, getVideoDocId, docId, watchNextPremium, isMyLearningPage} = props;
    const {courseId} = useParams()
    var ids = courseId.split("-");
    const avatarImage = videoDetails?.uploaded_by_pic ? process.env.REACT_APP_IMG_URL + videoDetails?.uploaded_by_pic : AvatarPic
    const commonHook = useVideosCommonHooks();
    var modulesData = [];
    var topicsData = []    

    const [isVideoModal, setIsVideoModal] = useState(false);
const [vidoePlayUrl, setVidoePlayUrl] = useState("");
const [introFileName,setIntroFileName] = useState(videoDetails.introvideo);

const playVideo = (val) => {
    commonHook?.promiseServiceNonLoading({"uri":"getImagePath",'imagePath':val}).then((data) => {
        setVidoePlayUrl(data.data);
        setIsVideoModal(true);
      });
};


useEffect(() => {

    commonHook?.promiseServiceNonLoading({"uri":"getImagePath",'imagePath':videoDetails.introvideo}).then((data) => {
        setIntroFileName(data.data);
        
      });
    
}, []);

const closeVideo = () => {
    setIsVideoModal(false);
};

    var breadcrumbList = []
    
    const userInfo = useSelector(state => state?.app?.userInfo);

    if(userInfo?.usertypeid === GURU){

        breadcrumbList =  [
            {
                text: 'Courses',
                link: '/instructor/course',
            },
            {
                text: videoDetails?.videocategoryname,
                link: isMyLearningPage ? '/instructor/course-video-details/' + videoDetails?.videoid : '/instructor/course-video-details/' + videoDetails?.videoid,
            },
        ]    

    }else{

            breadcrumbList =  [
                {
                    text: 'Main page',
                    link: '/trainee/dashboard',
                },
                {
                    text: videoDetails?.videocategoryname,
                    link: isMyLearningPage ? '/trainee/my-learning/video-details/' + videoDetails?.videoid : '/trainee/video-details/' + videoDetails?.videoid,
                },
            ]
    
    }

    useEffect(() => {
        if(isMyLearningPage){
      //  getVideoDocId(videoDetails?.videoid)
        }

    }, [])


     
    if(props?.getCourseData.length>=1){
        modulesData = [];
        console.log("props?.getCourseData?.data",props?.getCourseData)

        var val = 0;
           
                        props?.getCourseData?.map(function (item, index) {
                            
                                if(item?.data?.length >=1){

                                    val = 0;    
                                    item?.data?.map(function (ldata, lindex) {

                                    var obj={"moduletitle":ldata.category_name,"srno":ldata.category_id,"videos":ldata?.videos,"thumbnail":ldata?.thumbnail,"introvideo":ldata?.introvideo,"desc":ldata?.desc,"attacheddoc":ldata?.attacheddoc}    
                                      console.log("ldata",item)
                                    for(var i = 0;i<modulesData.length;i++){

                                        if(modulesData[i].srno == ldata.category_id){
                                            val = 1;
                                        }
                                    }
                                    if(val == 0){
                                        if(ldata.videos.length >=1){
                                        modulesData.push(obj);
                                        }
                                    }    
                                    })
                                }

                             
                            
                           
                        
                        })

                        modulesData.map(function (item, index) {
                            
                            item?.videos?.map(function (vdata, vindex) {
                                console.log("vdata",vdata);
                                var obj={"moduleid":item.srno,"title":vdata.title,"videoid":vdata.videoid,"thumbnail":vdata.thumbnail,"des":vdata.des}    
                                topicsData.push(obj);

                            });


                        });


                        
                        console.log("topicsData",topicsData);

    }


    

    return (
        <>
        
     
         
               
               <div className='row'>

                    <div className='col-md-4'  style={{padding:'0px'}}>
               
               
                        <SidePanelLists
                            {...props}
                            isSideOpen={!isSideOpen}
                            onOpenIsSide={!onOpenIsSide}
                            getAllVideosWatched={props.getAllVideosWatched}
                            usertypeid = {userInfo?.usertypeid}
                            setVidoePlayUrl={setVidoePlayUrl}
                            setIsVideoModal={setIsVideoModal}
                        />
                    </div>
                    


                    <div className='col-md-8' style={{padding:'0px'}}>
                
                
                    <HeroSlider options={[{"videofilename":introFileName}]} {...props}  />

                    <CourseDetailsPage


                       
                        title={videoDetails?.title}
                    
                        description={videoDetails?.desc}

                        ids ={ids}
                        
                        {...videoDetails}
                    />
                        
                    
                      
                    </div>
                    






                </div>
          
            
            

            {isVideoModal &&

                <Modal
                    className=""
                    canClose="onClose"
                    onClose={closeVideo}
                    onOuterClose={closeVideo}
                    modalContent={
                    
                        <VideoPlayer
                            videourl={process.env.REACT_APP_IMG_URL + vidoePlayUrl}
                            width="100%"
                            height="460"
                        />

                    }
                />







}          

        </>
    );
};

export default HomeCourseMainView;

