
import {  Route, Routes } from 'react-router-dom';
import ProtectedRoute from "./components/protectedRoute";

// Learner
import RegisterLearner from './pages/trainee/register';
import Subscribe from './pages/trainee/subscribe';
import Payment from './pages/trainee/payment';
import PaymentStatus from './pages/trainee/paymentStatus';
import PaymentSuccess from './pages/trainee/paymentSuccess';
import PaymentFailed from './pages/trainee/paymentFailed';
import ReferenceLink from './pages/trainee/referenceLink';
import PersonalInformation from './pages/trainee/personalInformation';
import AddressInformation from './pages/trainee/addressInformation';
import BankDetails from './pages/trainee/bankDetails';
import LearnerLikedVideo from "./pages/trainee/likedVideo";
import LearnerFavouriteGuruDetails from './pages/trainee/favouriteInstructorDetails';
import LearnerMain from "./pages/trainee/main";
import LearnerProfile from './pages/trainee/settings/profile';
import LearnerEditProfile from './pages/trainee/settings/profile/editProfile'
import LearnerSettingsBankDetails from './pages/trainee/settings/settingsBankDetails';
import LearnerSettingsEditBankDetails from './pages/trainee/settings/settingsBankDetails/editSettingsBankDetails';
import LearnerSettingsAccount from './pages/trainee/settings/account';
import LearnerMagazine from './pages/trainee/settings/magazine';
import LearnerMemberMyReport from './pages/trainee/member/myReport';
import LearnerMemberMySubscription from './pages/trainee/member/mySubscription';
import LearnerMemberMyNetwork from './pages/trainee/member/myNetwork';
import LearnerMemberMyCommission from './pages/trainee/member/myCommission';
import SearchController from './pages/trainee/searchGuru';
import OTPVerificationGuru from './pages/instructor/otpVerification';
import InstructorCoursesPage from './pages/trainee/instructorCourses';
import CoursePage from './pages/trainee/courses';
import TopTenVideos from './pages/trainee/topTenVideos';
import TodayAddedVideosPage from './pages/trainee/todayAddedCourse';
import TrendingTopicPage from './pages/trainee/trendingTopics';
import FavouriteCoursePage from './pages/trainee/favouriteCourse';
import CourseDetailsPage from './pages/trainee/courseDetails';
import CategeoryPurchase from './pages/trainee/categeoryPurchase';
import SubCategeoryPurchase from './pages/trainee/subCategeoryPurchase';
import CategeoryPayment from './pages/trainee/categeoryPayment';
import SubscribeDetailInformation from './pages/trainee/subscribeDetailInformation';
import TraineeDashboard from './pages/trainee/dashboard';
import CategeoryPage from './pages/trainee/member/myCategeoryComission';
import CheckoutPage from './pages/trainee/stripe';
import FreeCoursePage from './pages/trainee/courses/freeCourses';
import ChatWithInstructor from './pages/trainee/chatWithInstructor';
import ChatTrainerboard from './pages/trainee/chat';
import ChatHistory from './pages/trainee/chatWithInstructor/chatHistory';
import ChatEndConversation from './pages/trainee/chat/chatEndConversation';
import ChatLowBalance from './pages/trainee/chat/chatLowBalance';
import TrainerVideoConference from './pages/trainee/member/trainerVideoConference';
import CoursesDashboard from './pages/trainee/dashboard/coursesDashboard';
import InstructorsDashboard from './pages/trainee/dashboard/instructorsDashboard';
import UpcommingMeetings from './pages/trainee/instructors/upcommingMeetings';
import ScheduleMeeting from './pages/trainee/instructors/scheduleMeeting';
import MeetingHistory from './pages/trainee/instructors/meetingHistory';
import PremiumElitePurchase from './pages/trainee/categeoryPurchase/premiumElitePurchase';
import EliteSubCategoryPurchase from './pages/trainee/subCategeoryPurchase/eliteSubCategoryPurchase';
import FavouriteDashboard from './pages/trainee/dashboard/favouriteDashboard';
import FavouriteGuru from './pages/trainee/favouriteInstructor';
import LearnByTopicsPage from './pages/trainee/courses/learnByTopicsPage';
import MembersDashboard from './pages/trainee/dashboard/membersDashboard';
import WalletDashboard from './pages/trainee/dashboard/walletDashboard';
import WalletPaymentSuccess from './pages/trainee/wallet/walletPaymentSuccess';
import WalletPaymentFailed from './pages/trainee/wallet/walletPaymentFailed';
import AddToWallet from './pages/trainee/wallet/addToWallet';
import DeductedWallet from './pages/trainee/wallet/dedicatedWallet';
import Withdraw from './pages/trainee/wallet/WithDraw';
import UnauthorizedView from './pages/trainee/dashboard/unauthorizedView';






function TrainerRoute  ({languageText,isMemberUser}) {

                        return(
                            <Routes>
                                 <Route path="/trainee">
                                 <Route path="signup" element={<RegisterLearner text={languageText}/>}/>
                                 <Route path="signup/socialsignup" element={<RegisterLearner text={languageText}/>}/>
                                <Route element={<ProtectedRoute isLoggedIn={isMemberUser}/>}>
                                <Route index={true} element={<RegisterLearner text={languageText}/>}/>
                                <Route path="otp-verification" element={<OTPVerificationGuru text={languageText}/>}/>
                                <Route path="subscribe" element={<Subscribe text={languageText}/>}/>
                                <Route path="subscribe">
                                <Route path="purchase-categeory" element={<CategeoryPurchase  text={languageText}/>}/>
                                <Route path="premium-elite-purchase" element={<PremiumElitePurchase  text={languageText}/>}/>
                                <Route path="premium-elite-sub-category-purchase/:id" element={<EliteSubCategoryPurchase  text={languageText}/>}/>
                                <Route path="purchase-sub-categeory/:id" element={<SubCategeoryPurchase text={languageText} />}/>
                                <Route path="subscribe-information" element={<SubscribeDetailInformation text={languageText} />}/>
                                <Route path="categeory-payment/:id" element={<CategeoryPayment text={languageText} />}/>
                                </Route>


                                <Route path="courses" element={<CoursesDashboard text={languageText} />}/>
                                
                                <Route path="courses">
                                         <Route path="unauthorized-view" element={<UnauthorizedView text={languageText} />}/>
                                         <Route path="subscription-courses" element={<FreeCoursePage text={languageText} />}/>
                                         <Route path="free-courses" element={<FreeCoursePage text={languageText} />}/>
                                         <Route path="total-subscription-courses" element={<FreeCoursePage text={languageText} />}/>
                                         <Route path="today-added-courses" element={<TodayAddedVideosPage text={languageText} />}/>
                                         <Route path="favourite-course" element={<FavouriteCoursePage text={languageText} />}/>
                                         <Route path="trending-topics" element={<TrendingTopicPage text={languageText} />}/>
                                         <Route path="top-ten-videos" element={<TopTenVideos text={languageText} />}/>
                                         <Route path="learn-by-topics/:catId/:subCatId" element={<LearnByTopicsPage text={languageText}/>}/>
                                        
                                </Route>

                                <Route path="instructors" element={<InstructorsDashboard text={languageText} />}/>
                            
                                <Route path="instructors">

                                                <Route path="chat-with-instructor" element={<ChatWithInstructor text={languageText} />} />
                                                <Route path="chat-history" element={<ChatHistory text={languageText} />} />
                                                <Route path="chat-instructor-details/:userId" element={<ChatTrainerboard text={languageText} />} />
                                                <Route path="up-comming-meetings" element={<UpcommingMeetings text={languageText} />}/>
                                                <Route path="schedule-meetings" element={<ScheduleMeeting text={languageText} />}/>
                                                <Route path="meetings-history" element={<MeetingHistory text={languageText} />}/>
                                                <Route path="free-courses" element={<FreeCoursePage text={languageText} />}/>
                                                <Route path="today-added-courses" element={<TodayAddedVideosPage text={languageText} />}/>
                                                <Route path="favourite-course" element={<FavouriteCoursePage text={languageText} />}/>
                                                <Route path="trending-topics" element={<TrendingTopicPage text={languageText} />}/>
                                                <Route path="top-ten-videos" element={<TopTenVideos text={languageText} />}/>
                                                <Route path="favourite-instructor" element={<FavouriteGuru text={languageText}/>}/>

                                </Route>

                                <Route path="favourite" element={<FavouriteDashboard text={languageText} />}/>
                                <Route path="favourite">
                                    <Route path="favourite-instructor" element={<FavouriteGuru text={languageText}/>}/>
                                    <Route path="favourite-course" element={<FavouriteCoursePage text={languageText} />}/>
                                    <Route path="liked-course" element={<FavouriteCoursePage text={languageText} />}/>
                                    <Route path="liked-videos" element={<LearnerLikedVideo text={languageText}/>}/>
                                    <Route path="my-learning/video-details/:videoId" element={<LearnerMain text={languageText}/>}/>
                                    <Route path="favourite-instructor/favourite-instructor-details/all-videos/:guruId" element={<LearnerFavouriteGuruDetails text={languageText}/>}/>
                                    <Route path="favourite-instructor/favourite-instructor-details/newest/:guruId" element={<LearnerFavouriteGuruDetails text={languageText}/>}/>
                                    <Route path="favourite-instructor/favourite-instructor-details/top-viewed/:guruId" element={<LearnerFavouriteGuruDetails text={languageText}/>}/>
                                </Route>
                              
                                
                               
                                
                                <Route path="stripe-checkout" element={<CheckoutPage/> } />

                                <Route path="payment" element={<Payment text={languageText}/>}/>
                                <Route path="payment-package" element={<Payment text={languageText}/>}/>
                                <Route path="payment-status" element={<PaymentStatus text={languageText}/>}/>
                                <Route path="payment-success" element={<PaymentSuccess text={languageText}/>}/>
                                <Route path="payment-failed" element={<PaymentFailed text={languageText}/>}/>
                                <Route path="refer-friend" element={<ReferenceLink text={languageText}/>}/>
                                <Route path="chat-conversation-end/:sessionId" element={<ChatEndConversation text={languageText} />} />
                                <Route path="low-balance" element={<ChatLowBalance text={languageText} />} />
                                <Route path="video-meeting" element={<TrainerVideoConference/>}/>
                              
                               {/** <Route path="survey-question-1" element={<SurveyQuestionOne text={languageText}/>}/>
                                <Route path="survey-question-2" element={<SurveyQuestionOne text={languageText}/>}/>
                                <Route path="survey-question-3" element={<SurveyQuestionOne text={languageText}/>}/> */}


                                
                                <Route path="instructor-courses/:userId" element={<InstructorCoursesPage text={languageText} />}/>
                                <Route path="personal-information" element={<PersonalInformation text={languageText}/>}/>
                                <Route path="address-information" element={<AddressInformation text={languageText}/>}/>
                                <Route path="bank-details" element={<BankDetails text={languageText}/>}/>
                                <Route path="dashboard" element={<TraineeDashboard text={languageText}/>}/>
                           
                                <Route path="course-details/:courseId" element={<CourseDetailsPage text={languageText}/>}/>
                                <Route path="video-details/:videoId" element={<LearnerMain text={languageText}/>}/>
                                <Route path="my-learning/video-details/:videoId" element={<LearnerMain text={languageText}/>}/>

                                <Route path="wallet" element={<WalletDashboard text={languageText} />}/>
                                <Route path="wallet">
                                    <Route path="added-wallet-history" element={<AddToWallet text={languageText}/>}/>
                                    <Route path="deducted-wallet-history" element={<DeductedWallet text={languageText}/>}/>
                                    <Route path="withdraw" element={<Withdraw text={languageText}/>}/>
                                   <Route path="wallet-payment-success/:sessionId" element={<WalletPaymentSuccess text={languageText}/>}/>
                                    <Route path="wallet-payment-failed" element={<WalletPaymentFailed text={languageText}/>}/>
                                    <Route path="withdraw-history" element={<LearnerMemberMySubscription text={languageText}/>}/>
                                   
                                </Route>
                                
                                <Route path="members" element={<MembersDashboard text={languageText} />}/>
                                <Route path="members">
                                    <Route path="my-report" element={<LearnerMemberMyReport text={languageText}/>}/>
                                    <Route path="my-subscription" element={<LearnerMemberMySubscription text={languageText}/>}/>
                                    <Route path="my-network" element={<LearnerMemberMyNetwork text={languageText}/>}/>
                                    <Route path="my-commission" element={<LearnerMemberMyCommission text={languageText}/>}/>
                                    <Route path="categories-commission" element={<CategeoryPage text={languageText}/>}/>
                                </Route>

                                <Route path="settings">
                                    <Route path="profile" element={<LearnerProfile text={languageText}/>}/>
                                    <Route path="magazine" element={<LearnerMagazine text={languageText}/>}/>
                                    <Route path="profile/edit-profile" element={<LearnerEditProfile text={languageText}/>}/>
                                    <Route path="bank-details" element={<LearnerSettingsBankDetails text={languageText}/>}/>
                                    <Route path="bank-details/edit-bank-details" element={<LearnerSettingsEditBankDetails text={languageText}/>}/>
                                    <Route path="reset-password" element={<LearnerSettingsAccount text={languageText}/>}/>
                                </Route>
                                <Route path="search" element={<SearchController text={languageText}/>}/>

                            </Route>
                            <Route path="*" element={<p>There's nothing here: 404!</p>}/>
                            </Route>
                            </Routes>
                            
                        );
                          
                        
    
}

export default TrainerRoute;
