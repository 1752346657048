import React, {useState} from "react";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import Text from "../text";
import Input from "../input";
import Button from "../button";
import Flex from "../flex";
import ShareVia from "../shareVia";
import {useSelector} from "react-redux";
import './shareAffiliateLink.scss';
import useHelper from "../../hooks/helpers.hook";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import { URL } from "../../constants/app.constants";

const ShareAffiliateLink = props => {
    const [isCopied, setIsCopied] = useState(false)
    const {text} = props
    const refCode = useSelector(state => state?.app?.userInfo?.myrefcode)
    const affiliateLink = URL+'/referral/'+refCode

    const onCopy = ()=>{
        setIsCopied(true)
        setTimeout(() => {
            setIsCopied(false);
        }, 1500);
    }


    const formik = useFormik({
        initialValues: {
            affiliateLink: affiliateLink,
        },
        validationSchema: Yup.object({
        }),
        onSubmit: (values, actions) => {
        },
    });
    return(
        <>
            <div className="share-affiliate-link-wrapper shadow bg-color-grey-scale-1">
                <Text type="H5" className="text-dark" text="Share the Affiliate Link" />
                <form onSubmit={formik.handleSubmit}>
                    <div className="formBox">
                        <Input
                            secondary
                            type="text"
                            name="affiliateLink"
                            placeholder=""
                            value={formik.values.affiliateLink}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            formControlClassName="text-center border-0"
                        />
                    </div>
                    {/* <Flex justifyContent="center" className="mb-10">

                        <CopyToClipboard onCopy={onCopy} text="Copy Link">
                            <Button
                                type="button"
                                text="Copy Link"
                                large
                                className="btn-light mt-2"
                            />
                        </CopyToClipboard>


                    </Flex> */}
                </form>
                <ShareVia {...props} affiliateLink={affiliateLink}/>
            </div>
        </>
    )
}

export default ShareAffiliateLink;
